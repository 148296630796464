import api from '@/store/api';

const SET_ROLES = 'set_roles';

export default {
    namespaced: true,

    state() {
        return {
            roles: [],
            rolesLoaded: false,
        };
    },

    getters: {
        sortedRoles(state) {
            return [...state.roles].sort((role1, role2) => role1.alias.localeCompare(role2.alias));
        },
        isExistingRole: (state) => (alias) => !!state.roles.find((role) => role.alias === alias),
    },

    actions: {
        async fetchRoles({ commit, state }, force = false) {
            if (state.rolesLoaded && !force) {
                return;
            }

            return api.configuration.roles.getRoles().then(({ data }) => {
                commit(SET_ROLES, data.resources);
            });
        },
    },

    mutations: {
        [SET_ROLES](state, roles) {
            state.roles = roles;
            state.rolesLoaded = true;
        },
    },
};
