export default {
    name: 'Quote',
    image: 'quote.png',
    fullPageBorder: false,
    content: `
        <section class="ht-quote-section">
            <h2 class="title">Lorem ipsum dolor sit amet.</h2>

            <p class="text-open-quote">
                <img src="/static/images/settings/enterprisepages/sections/misc/quote/quote-start.png" alt="" width="63" height="55" />
            </p>

            <p class="text-quote">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A animi beatae consectetur, corporis dolor doloremque eaque eligendi, hic impedit in inventore maxime molestias natus neque non numquam, officiis optio perspiciatis sunt veniam! Autem dignissimos error magni optio suscipit vero, voluptatem. Cupiditate eaque eveniet illum ipsam, non obcaecati repudiandae sequi sint!</p>

            <p class="text-close-quote">
                <img src="/static/images/settings/enterprisepages/sections/misc/quote/quote-end.png" alt="" width="63" height="55" />
            </p>

            <p>
                <a class="quote-button" href="#" target="_blank" rel="noopener">Lorem ipsum dolor sit amet &rarr;</a>
            </p>
        </section>
    `,
    css: `
        .ht-quote-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 40px 0;
        }

        .title {
            font-size: 32px;
            line-height: 36px;
            font-weight: bold;
            color: var(--primary-base);
            margin-bottom: 0px;
        }

        .text-open-quote {
            align-self: start;
        }

        .text-quote {
            font-size: 16px;
            line-height: 21px;
            font-weight: regular;
        }

        .text-close-quote {
            align-self: end;
        }

        .quote-button {
            display: inline-block;
            background-color: var(--primary-base);
            font-weight: bold;
            color: var(--primary-lighter);
            text-align: center;
            padding: 12px 24px;
            font-size: 12pt;
            text-decoration: none;
            border: none;
            border-radius: 8px;
        }

        .quote-button:hover {
            background-color: var(--primary-darker);
        }
    `,
};
