<template>
    <HtFormSelector
        :id="id"
        :value="currentValue"
        :label="label"
        :description="description"
        :placeholder="placeholder"
        :name="name"
        :show-asterisk="showAsterisk"
        :options="processedOptions"
        :allow-empty="allowEmpty"
        :is-single="isSingle"
        :track-by="trackBy"
        :max-height="maxHeight"
        :open-direction="openDirection"
        :disabled="disabled"
        :cypress="cypress"
        :search-value.sync="searchValue"
        :loading="loading"
        @input="onUpdate"
    >
        <template #noOptions>
            <t>Please enter text to find users</t>
        </template>

        <template #image="{ option }">
            <img
                class="image"
                :src="option.image"
                alt="avatar"
            >
        </template>
    </HtFormSelector>
</template>

<script>
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormMixin from '@/components/globals/HtFormMixin';
import uniqBy from 'lodash.uniqby';

export default {
    name: 'HtFormUserSelector',
    components: {
        HtFormSelector,
    },
    mixins: [
        HtFormMixin,
    ],
    props: {
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: undefined,
        },
        options: {
            type: Array,
            default: () => [],
        },
        fromApi: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        isSingle: {
            type: Boolean,
            default: false,
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        maxHeight: {
            type: [Number, undefined],
            default: undefined,
        },
        openDirection: {
            type: String,
            default: '',
        },
        /**
         * @EdgeCase LIN-1858
         * programId is used to filter user who didn't have corresponding in_progress or draft program.
         */
        programId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            apiOptions: [],
            searchValue: null,
            currentValue: this.isSingle ? null : [],
        };
    },
    computed: {
        processedOptions() {
            let { options } = this;
            if (this.fromApi) {
                options = [...options, ...this.apiOptions];
            }

            const results = options
                .map((user) => {
                    const image = user.image !== this.$Utils.getDefaultUserImage()
                        ? this.$Utils.resolveS3PublicImage(user.image || user.company_user?.image)
                        : this.getDefaultImageUser(user.gender);

                    let name = `${user.firstname} ${user.lastname}`;
                    if (user.company_role) {
                        name += '<b>';
                        name += user.company_role.is_heyteam
                            ? this.translate(user.company_role.alias) : user.company_role.alias;
                        name += '</b>';
                    }
                    return {
                        ...user,
                        image,
                        name,
                    };
                });

            return uniqBy(results, 'id');
        },
    },
    watch: {
        searchValue() {
            if (this.fromApi && this.searchValue) {
                this.loading = true;
                this.apiOptions = [];

                this.$http.post('company_user_search', {
                    search: this.searchValue,
                    limit: 10,
                    programId: this.programId,
                }).then((res) => {
                    this.apiOptions = res.data.search;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    },
    created() {
        this.currentValue = this.value;
    },
    methods: {
        onUpdate(value) {
            this.currentValue = value;
            this.$emit('input', value);
        },
        getDefaultImageUser(gender) {
            switch (gender) {
            case 'male':
                return '/static/images/Illu_Man.png';
            case 'female':
                return '/static/images/Illu_Woman.png';
            default:
                return '/static/images/Illu_Anonymous.png';
            }
        },
    },
};
</script>
