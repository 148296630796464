<template>
    <HtFormGroup
        class="ht-form-selector"
        :class="{ multiple: !isSingle }"
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
        :has-delete-icon="isDeletable"
        :description="description"
        @onDelete="$emit('onDeleteParent')"
    >
        <div class="group-container">
            <div
                v-if="hasPrependSlot"
                class="prepend"
            >
                <slot name="prepend" />
            </div>
            <multiselect
                :id="id"
                :name="name"
                :value="currentValue"
                :placeholder="placeholder"
                :disabled="disabled"
                :label="labelOptions"
                :allow-empty="allowEmpty"
                :track-by="trackBy"
                :options="processedOptions"
                :internal-search="false"
                :close-on-select="isSingle"
                :multiple="!isSingle"
                :searchable="isSearchable"
                :show-labels="false"
                :open-direction="openDirection"
                :max-height="maxHeight"
                :data-cy="cypress"
                :loading="loading"
                @search-change="onSearchChange"
                @input="onUpdateValue"
                @remove="onRemoveOption"
                @select="onSelectOption"
                @open="open"
                @close="close"
            >
                <template #caret="{ toggle }">
                    <slot
                        v-if="!loading"
                        name="caret"
                    >
                        <div
                            class="multiselect-caret"
                            @mousedown.prevent.stop
                        >
                            <FontAwesomeIcon
                                v-if="!empty && allowEmpty"
                                icon="times-circle"
                                class="icon-clear"
                                @click="clear"
                            />
                            <FontAwesomeIcon
                                :icon="
                                    isToggled === false
                                        ? ['fal', 'chevron-down']
                                        : ['fal', 'chevron-up']
                                "
                                class="icon-caret"
                                @click="toggle"
                            />
                        </div>
                    </slot>
                </template>
                <template #noResult>
                    <slot name="noResult">
                        <t>No result found.</t>
                    </slot>
                </template>
                <template #noOptions>
                    <slot name="noOptions">
                        <t>List is empty.</t>
                    </slot>
                </template>
                <template #singleLabel="{ option }">
                    <slot
                        name="singleLabel"
                        :option="option"
                    >
                        <div class="label">
                            <slot
                                name="image"
                                :option="option"
                            />
                            <span v-html="getOptionLabel(option)" />
                        </div>
                    </slot>
                </template>
                <template #option="{ option }">
                    <slot
                        name="option"
                        :option="option"
                    >
                        <div
                            class="d-flex col-12"
                            :class="enableSelector && option.type === 'selector' ? 'border-grey' : ''"
                        >
                            <div
                                v-if="!isSingle"
                                class="checkbox-wrapper"
                            >
                                <HtFormCheckbox
                                    :id="`checkbox-${option.id}`"
                                    :name="`checkbox-${option.id}`"
                                    :disabled="option.$isDisabled"
                                    :checked="option.$isSelected ?? currentValue.includes(option)"
                                />
                            </div>

                            <span class="label">
                                <slot
                                    name="image"
                                    :option="option"
                                />
                                <span v-html="getOptionLabel(option)" />
                            </span>
                        </div>
                    </slot>
                </template>
                <template #selection="scopedProps">
                    <template v-if="selectionLabel">
                        <span
                            v-show="currentValue.length"
                            class="multiselect__single"
                        >
                            {{ selectionLabel }}
                        </span>
                    </template>
                    <slot
                        v-else
                        name="selection"
                        v-bind="scopedProps"
                    />
                </template>
                <template #tag="{ option, remove }">
                    <slot
                        name="tag"
                        :option="option"
                    >
                        <HtChip
                            :label="getOptionLabel(option)"
                            :deletable="isOptionDeletable(option)"
                            @delete="remove(option)"
                        >
                            <template #image>
                                <slot
                                    name="image"
                                    :option="option"
                                />
                            </template>
                        </HtChip>
                    </slot>
                </template>
                <template
                    v-if="taggable && searchValue"
                    #afterList
                >
                    <div class="multiselect__option tag">
                        <HtButton
                            type="secondary"
                            size="small"
                            @click.native="createOption"
                        >
                            <div class="create-button-content">
                                <FontAwesomeIcon
                                    icon="plus"
                                    class="icon-plus"
                                />
                                <t>Create</t>
                            </div>
                        </HtButton>
                        {{ searchValue }}
                    </div>
                </template>
            </multiselect>
        </div>
    </HtFormGroup>
</template>

<script>
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtChip from '@/components/globals/HtChip.vue';
import HtFormSelectorMixin from '@/components/globals/Selectors/HtFormSelectorMixin';
import HtFormCheckbox from '@/components/globals/Checkbox/HtFormCheckbox.vue';
import HtFormMixin from '../HtFormMixin';

export default {
    name: 'HtFormSelector',
    components: {
        HtChip,
        HtFormGroup,
        HtButton,
        HtFormCheckbox,
    },
    mixins: [HtFormMixin, HtFormSelectorMixin],

    props: {
        isDeletable: {
            type: Boolean,
            default: false,
        },
        // Fills the selection slot
        selectionLabel: {
            type: String,
            default: '',
        },
        // Used for showing specific label in object of options
        labelOptions: {
            type: String,
            default: 'name',
        },
        // Used to make select taggable
        taggable: {
            type: Boolean,
            default: false,
        },
        // Used for emit searching (Needs to be sync)
        searchValue: {
            type: String,
            default: '',
        },
        isSearchable: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        enableSelector: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            currentValue: null,
            isToggled: false,
            searchQuery: '',
        };
    },

    computed: {
        hasPrependSlot() {
            return !!this.$slots.prepend;
        },
        empty() {
            return this.isSingle ? this.currentValue === null : !this.currentValue.length;
        },
        processedOptions() {
            let { options } = this;
            if (this.options?.length && this.options[0] && typeof this.options[0] === 'object') {
                options = this.options
                    .map((option) => ({
                        ...option,
                        $isDisabled: this.isOptionDisabled(option),
                        $isSelected: this.isOptionSelected(option),
                    }))
                    .filter((option) => this.$Utils.isSubstringWithoutDiacritics(this.searchQuery, this.getOptionLabel(option)) || option.$isSelected);
            }

            if (this.enableSelector) {
                const selectAll = options.every((o) => o.$isSelected === true);
                const selector = {
                    name: selectAll ? this.translate('Unselect All') : this.translate('Select All'),
                    type: 'selector',
                    $isSelected: selectAll,
                };
                options.unshift(selector);
            }

            return options;
        },
    },
    mounted() {
        if (!this.isSingle && this.value?.length) {
            this.resizeInput();
        }
    },

    methods: {
        getOptionLabel(option) {
            return option[this.labelOptions] ?? option;
        },
        isOptionDeletable(option) {
            return option.is_deletable === undefined || option.is_deletable;
        },
        isOptionSelected(option) {
            const optionValue = this.getOptionTrackedValue(option);

            return this.isSingle
                ? this.getOptionTrackedValue(this.value) === optionValue
                : this.value
                    .map((oneValue) => this.getOptionTrackedValue(oneValue))
                    .includes(optionValue);
        },
        isOptionDisabled(option) {
            return (
                option.$isDisabled
                || (!this.isOptionDeletable(option) && this.isOptionSelected(option))
            );
        },

        onSearchChange(query) {
            if (typeof this.searchValue !== 'undefined' && this.isSearchable === true) {
                this.$emit('update:searchValue', query);
                this.searchQuery = query;
                this.resizeInput();
            }
        },

        onRemoveOption(option, id) {
            this.$emit('onRemove', option, id);
        },

        onSelectOption(option, id) {
            this.$emit('onSelect', option, id);
        },

        open() {
            this.isToggled = true;
            this.$emit('open');
        },
        close() {
            this.isToggled = false;
            this.$emit('close');
        },
        clear() {
            if (this.allowEmpty === true) {
                this.$emit('input', this.isSingle === true ? null : []);
            }
        },
        createOption() {
            this.$emit('createOption', this.searchValue);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.ht-form-selector {
    ::v-deep input {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
    ::v-deep .ht-form-description {
        font-size: 12px;
        color: $neutral-700;
    }

    ::v-deep .multiselect {
        cursor: pointer;

        .multiselect-caret {
            position: absolute;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            padding-right: 10px;
            z-index: 1;
            color: $neutral-black;

            .icon-clear {
                font-size: 16px;
                color: $neutral-500;
            }
            .icon-caret {
                margin-left: 4px;
                width: 30px;
                font-size: 12px;
            }
        }

        .multiselect__input {
            caret-color: $primary;
            margin: 0;
        }

        .multiselect__placeholder {
            color: $neutral-500;
            margin: 0;
        }

        .multiselect__single {
            margin: 0;
            padding: 0;
            font-size: 14px;

            .label {
                display: flex;
                align-items: center;

                .image {
                    height: 24px;
                    width: 24px;
                    margin-right: 8px;
                    border-radius: 100%;
                }
                .role-font-size {
                    font-size: 12px !important;
                }
            }

            .single-ellipsis {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 80%;
                overflow: hidden;
            }
        }

        .multiselect__tags {
            padding: 9px 11px;
            padding-right: 65px;
            display: flex;
            flex-flow: row wrap;
            justify-content: start;
            align-items: center;
            min-height: 44px;
            border-radius: 6px;

            .multiselect__tags-wrap {
                max-width: calc(100% - 36px);
            }

            .multiselect__tags-wrap {
                display: flex;
                flex-flow: row wrap;
                margin-bottom: -4px; // to compensate the margin-bottom of items
            }
        }

        &.multiselect--active {
            cursor: default;

            .multiselect__input {
                display: flex;
            }
            .multiselect__input::placeholder {
                color: transparent;
            }
            .multiselect__tags {
                border: 1px solid $primary;
            }
        }

        &.multiselect--disabled {
            opacity: 1;

            .multiselect__tags,
            .multiselect__single {
                background: $neutral-200;
                color: $neutral-500;
            }

            .multiselect-caret {
                display: none;
            }
        }
    }

    ::v-deep .multiselect__content-wrapper {
        overflow: auto;
        border: 1px solid $neutral-300;
        border-radius: 4px;
        margin: 8px 0;
        padding: 8px;

        .multiselect__content {
            position: relative;

            .multiselect__option {
                font-size: 14px;
                line-height: 140%;
                border-radius: 4px;
                color: $neutral-black;
                border: 1px solid transparent;
                display: flex;
                align-items: center;
                background: transparent;
                padding: 0;

                .label {
                    display: flex;
                    align-items: center;
                    padding: 10px;

                    .image {
                        height: 24px;
                        width: 24px;
                        margin-right: 8px;
                        border-radius: 100%;
                    }
                    .role-font-size {
                        font-size: 12px !important;
                    }
                }
                .checkbox-wrapper {
                    position: sticky;
                    padding: 10px 8px 10px 10px;
                    left: 0;
                    top: 0;
                    background-color: $neutral-white;
                    .checkbox {
                        width: 16px;
                        height: 16px;
                        border-radius: 2px;
                        border: 1px solid $neutral-500;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .check {
                            color: $neutral-white;
                            width: 8px;
                            height: 8px;
                        }
                    }
                }

                .checkbox.disabled {
                    border: 1px solid $neutral-500;
                }
                .checkbox.selected.disabled {
                    background: $neutral-500;
                }
                .tag {
                    font-weight: 700;
                    color: $primary;
                }
                &:after {
                    display: none;
                }

                .border-grey {
                    border-bottom: 1px #e1e4e7 solid;
                }
            }
            .multiselect__option--disabled {
                background: $neutral-200 !important;
                color: $neutral-500 !important;
            }
            .multiselect__option--highlight {
                background: $primary-background;
                color: $neutral-black;

                .checkbox-wrapper {
                    background: $primary-background;
                }
            }
            .multiselect__option.multiselect__option--selected {
                color: $neutral-black;
                font-weight: normal;

                .checkbox {
                    border: 1px solid $primary;
                    background: $primary;
                }
            }
            .multiselect__option.tag {
                display: flex;
                gap: 8px;

                .create-button-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    gap: 4px;
                    text-transform: uppercase;

                    .icon-plus {
                        height: 8px;
                        width: 8px;
                    }
                }
            }
        }
    }
    &.multiple {
        ::v-deep .multiselect__content-wrapper .multiselect__content .multiselect__option .label {
            padding: 10px 10px 10px 0;
        }
    }
    &:not(.multiple) {
        ::v-deep .multiselect__option.multiselect__option--selected {
            border: 1px solid $primary;
        }
    }

    .group-container {
        display: flex;
        justify-content: start;
        align-items: center;

        .prepend {
            border: solid 1px #e8e8e8;
            padding: 9px 11px;
            display: flex;
            flex-flow: row wrap;
            justify-content: start;
            align-items: center;
            min-height: 44px;
            border-radius: 6px;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .prepend + .multiselect {
            ::v-deep .multiselect__tags {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;
            }
        }

        &:focus-within {
            .prepend {
                border: 1px solid var(--primary-base);
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .prepend + .multiselect--active {
                ::v-deep .multiselect__tags {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left: none;
                }
            }
        }
    }
}
</style>
