import Auth from '@/services/Auth';

import LayoutProfile from '@/layouts/Profile.vue';
import ProfileInfo from '@/components/pages/profile/ProfileInfo.vue';
import ProfileProgram from '@/components/pages/profile/ProfileProgram.vue';
import ProfileResources from '@/components/pages/profile/PageUserResources.vue';
import ProfileAdministrative from '@/components/pages/profile/ProfileAdministrative.vue';
import ProfileActivities from '@/components/pages/profile/PageActivities.vue';

export default [
    {
        path: '/Profile/:company_user_id',
        component: LayoutProfile,
        meta: {
            context: [
                Auth.CONTEXT.owner,
            ],
        },
        props: (route) => ({
            companyUserId: parseInt(route.params.company_user_id, 10),
            companyUserProgramId: parseInt(route.params.company_user_program_id, 10),
        }),
        children: [
            {
                path: 'Info',
                alias: '/',
                name: 'Profile',
                component: ProfileInfo,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Program',
                alias: 'Programme', // patch, alias à supprimer le 10 septembre 2021
                name: 'ProfileProgram',
                component: ProfileProgram,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Program/:company_user_program_id',
                name: 'ProfileProgramDetail',
                component: ProfileProgram,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Resources',
                name: 'ProfileResources',
                component: ProfileResources,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Administrative',
                name: 'ProfileAdministrative',
                component: ProfileAdministrative,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Activities',
                name: 'ProfileActivities',
                component: ProfileActivities,
                meta: {
                    permission: true,
                    access: [Auth.ROLE.admin, 'company_user_audit'],
                    context: [Auth.CONTEXT.company_user_audit],
                },
            },
        ],
    },
];
