<template>
    <StepperTabLayout>
        <template
            v-if="programsLoaded"
            #main
        >
            <template v-if="!requirementCategory.display_survey">
                <ParametersBox
                    class="mb-5"
                    :title="translate('Program')"
                >
                    <div>
                        <HtFormMultiSelect
                            id="program-selection"
                            v-validate.disable="'required'"
                            :value="selectedPrograms"
                            :label="translate('Associated programs')"
                            name="program-selection"
                            :options="programsOptions"
                            :data-vv-as="translate('associated program')"
                            :show-optional="false"
                            :placeholder="translate('No program linked to this resource')"
                            :disabled="!requirementCategory.resource.is_editable || hasVariablesInDynamicDocument"
                            :tooltip="getAssociatedProgramsTooltip"
                            :is-single="hasDynamicDocument"
                            cypress="program-list"
                            @onRemove="onRemovePrograms"
                            @onSelect="onSelectPrograms"
                        />
                        <HtFormSwitch
                            id="display-chatbot"
                            :value="requirementCategory.display_chatbot"
                            :label="translate('Request this requirement in the welcome chatbot')"
                            :description="translate('The requirements will be requested in the welcome chatbot, unless the onboardee logs in before their availability date')"
                            :on-label="''"
                            :off-label="''"
                            name="display-chatbot"
                            class="mb-0"
                            :disabled="!requirementCategory.resource.is_editable || hasDynamicDocument"
                            @input="setDisplayChatbot"
                        />
                    </div>
                </ParametersBox>
                <ParametersBox
                    v-for="(resourceProgram, index) in requirementCategory.resource.programs"
                    :key="index"
                    class="mt-5"
                    :title="translate('Filters') + ' ' + resourceProgram.program.name"
                >
                    <ProgramResourceFilters
                        v-model="resourceProgram.filters"
                        :program-id="resourceProgram.company_program_id"
                        :disabled="!requirementCategory.resource.is_editable"
                    />
                </ParametersBox>
            </template>
            <ParametersBox
                :title="translate('Participants')"
            >
                <HtFormRoleSelector
                    v-if="!requirementCategory.display_survey"
                    id="recipient"
                    v-validate.disable="'required'"
                    name="recipient"
                    :label="translate('Main Recipient')"
                    :description="translate('Main recipient will have to complete and / or sign the requirements of the administrative category')"
                    :options="getRoleOptions"
                    show-asterisk
                    is-single
                    :disabled="!requirementCategory.resource.is_editable"
                    :value="getParticipantExecutor"
                    @input="(value) => addRecipients([value], 'executor')"
                />
                <HtFormGroup
                    v-else
                    :label="translate('Main Recipient')"
                    :description="translate('Main recipient will have to complete and / or sign the requirements of the administrative category')"
                    is-required
                    show-asterisk
                >
                    <ParticipantList
                        ref="recipientsInput"
                        type="stacked"
                        :single-selection="false"
                        :can-add="false"
                        has-role
                        :users="getParticipantExecutor"
                        :disabled="!requirementCategory.resource.is_editable"
                        cypress="requirement-recipients"
                    />
                </HtFormGroup>
                <div v-if="!requirementCategory.display_survey">
                    <HtFormGroup
                        :label="translate('Contributors')"
                        :description="translate('Additional contributors will have to either complete or sign requirements of the administrative category in addition of the main recipient.')"
                        :is-required="true"
                    >
                        <OrderableRoleList
                            :unavailables="getUnavailablesForSigning"
                            :value="getParticipantSigners"
                            :disable-drag="!is_signers_orderable"
                            :role-options="getRoleOptionsWithoutEnrolleeAndWithAsignedRoleDisabled"
                            :add-label="translate('Add contributors')"
                            :disabled="!requirementCategory.resource.is_editable"
                            @addRoleSlot="() => addParticipant(roleTypes.signer, is_signers_orderable)"
                            @updateRole="(params) => updateParticipant(params, roleTypes.signer)"
                            @deleteRole="(role) => removeParticipant(role, roleTypes.signer)"
                            @orderChanged="(roles) => participantsOrderChanged(roles, roleTypes.signer)"
                        />
                    </HtFormGroup>
                    <HtFormGroup>
                        <HtFormSwitch
                            id="allow-signers-order"
                            v-model="is_signers_orderable"
                            :show-optional="false"
                            :label="translate('Respect sign order')"
                            :description="translate('Order will only be applied to files to sign requirements')"
                            :on-label="''"
                            :off-label="''"
                            name="allow-signers-order"
                            class="mb-0"
                            :disabled="!requirementCategory.resource.is_editable"
                            @input="setParticipantsOrders(roleTypes.signer)"
                        />
                    </HtFormGroup>
                    <HtFormGroup>
                        <HtFormSwitch
                            id="is-signing-notifiable"
                            :value="isSigningNotifEnabledForCategory"
                            :label="translate('Notify these roles whenever the category is available for signature.')"
                            :on-label="''"
                            :off-label="''"
                            name="is-signing-notifiable"
                            class="mb-0"
                            :disabled="!isSigningNotifEnabledForCompany || !Boolean(getParticipantSigners.length)"
                            :show-optional="false"
                            @input="setSigningNotifForCategory"
                        />
                    </HtFormGroup>
                    <HtFormRoleSelector
                        id="preparer"
                        ref="preparerInput"
                        name="preparer"
                        :label="translate('Delegate uploading the files to sign')"
                        :options="getRoleOptionsWithoutEnrollee"
                        :is-single="true"
                        :value="getPreparer"
                        :disabled="!requirementCategory.resource.is_editable"
                        cypress="requirement-preparer"
                        @input="(value) => addRecipients([value], 'preparer')"
                    />

                    <HtFormGroup
                        :label="translate('Validators')"
                        :description="translate('Validators will have the task of verifying of requirements\'s validity')"
                    >
                        <OrderableRoleList
                            :unavailables="getUnavailableForValidator"
                            :value="getValidators"
                            :disable-drag="!is_validators_orderable"
                            :role-options="getRoleOptionsWithoutEnrollee"
                            :add-label="translate('Add validators')"
                            :disabled="!requirementCategory.resource.is_editable"
                            @addRoleSlot="() => addParticipant(roleTypes.validator, is_validators_orderable)"
                            @updateRole="(params) => updateParticipant(params, roleTypes.validator)"
                            @deleteRole="(role) => removeParticipant(role, roleTypes.validator)"
                            @orderChanged="(roles) => participantsOrderChanged(roles, roleTypes.validator)"
                        />
                    </HtFormGroup>
                    <HtFormGroup>
                        <HtFormSwitch
                            id="allow-validators-order"
                            v-model="is_validators_orderable"
                            :show-optional="false"
                            :label="translate('Respect validator order')"
                            :on-label="''"
                            :off-label="''"
                            name="allow-validators-order"
                            class="mb-0"
                            :disabled="!requirementCategory.resource.is_editable"
                            @input="setParticipantsOrders(roleTypes.validator)"
                        />
                    </HtFormGroup>
                    <HtFormGroup>
                        <HtFormSwitch
                            id="is-validation-notifiable"
                            :value="isValidationNotifEnabledForCategory"
                            :label="translate('Notify these roles whenever the category is available for validation.')"
                            :on-label="''"
                            :off-label="''"
                            name="is-validation-notifiable"
                            class="mb-0"
                            :disabled="!isValidationNotifEnabledForCompany || !Boolean(getValidators.length)"
                            :show-optional="false"
                            @input="setValidationNotifForCategory"
                        />
                    </HtFormGroup>
                    <HtFormGroup>
                        <HtFormSwitch
                            id="is-rejection-notifiable"
                            :value="isRejectionNotifEnabledForCategory"
                            :label="translate('Notify the executor whenever the requirement is rejected.')"
                            :on-label="''"
                            :off-label="''"
                            name="is-rejection-notifiable"
                            class="mb-0"
                            :disabled="!isRejectionNotifEnabledForCompany || !Boolean(getValidators.length)"
                            :show-optional="false"
                            @input="setRejectionForCategory"
                        />
                    </HtFormGroup>
                </div>
                <HtFormGroup>
                    <HtFormRoleSelector
                        id="accessors"
                        ref="accessorsInput"
                        name="accessors"
                        :label="translate('Accessible by')"
                        :description="translate('Decide who can access the requirements in this category when assigned to someone')"
                        :options="getRoleOptionsWithoutEnrollee"
                        :value="getCategorySupervisors"
                        :disabled="!requirementCategory.resource.is_editable"
                        class="mb-0"
                        cypress="requirement-accessors"
                        @input="(value) => addRecipients(value)"
                    />
                </HtFormGroup>

                <HtFormGroup>
                    <HtFormSwitch
                        id="is-notifiable"
                        :value="requirementCategory.is_notifiable"
                        :label="translate('Notify these roles whenever the category is completed.')"
                        :on-label="''"
                        :off-label="''"
                        name="is-notifiable"
                        class="mb-0"
                        :disabled="!isNotifiable || !Boolean(getCategorySupervisors.length)"
                        :show-optional="false"
                        @input="setIsNotifiable"
                    />
                </HtFormGroup>
            </ParametersBox>
        </template>
        <template #buttonsFooter>
            <div class="mt-4">
                <Button
                    :state="buttonState"
                    class="negative mr-4"
                    @click="$emit('goTo', 'settings')"
                >
                    <t>Back</t>
                </Button>
                <Button
                    v-if="requirementCategory.resource.is_editable"
                    :state="buttonState"
                    @click="onSave"
                >
                    <t>Validate</t>
                </Button>
            </div>
        </template>
    </StepperTabLayout>
</template>

<script>
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import { mapState } from 'vuex';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import HtFormRoleSelector from '@/components/globals/Selectors/HtFormRoleSelector.vue';
import OrderableRoleList from '@/components/globals/containers/OrderableRoleList.vue';
import cloneDeep from 'lodash.clonedeep';
import api from '@/store/api';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';

export default {
    name: 'RequirementFormTarget',

    components: {
        StepperTabLayout,
        HtFormMultiSelect,
        ParametersBox,
        HtFormGroup,
        HtFormSwitch,
        HtFormRoleSelector,
        OrderableRoleList,
        ParticipantList,
        ProgramResourceFilters,
    },

    mixins: [DefaultFiltersMixin],

    data() {
        return {
            roleTypes: CompanyResourceProgramParticipant.TYPES,
            participants: [],
            resourceableClass: CompanyRequirementCategory.RESOURCEABLE_CLASS,
            is_signers_orderable: false,
            is_validators_orderable: false,
            hasDynamicDocument: false,
        };
    },

    computed: {
        notifCompleted() {
            return this.$store.getters['configuration/emailTemplates/getBySlug']('requirementCategoryCompleted');
        },
        notifToValidate() {
            return this.$store.getters['configuration/emailTemplates/getBySlug']('requirementCategoryToValidate');
        },
        notifRejected() {
            return this.$store.getters['configuration/emailTemplates/getBySlug']('requirementCategoryRejected');
        },
        notifToSign() {
            return this.$store.getters['configuration/emailTemplates/getBySlug']('newRequirementToSign');
        },
        isNotifiable() {
            return this.notifCompleted.is_enabled;
        },
        isValidationNotifEnabledForCompany() {
            return this.notifToValidate.is_enabled;
        },
        isRejectionNotifEnabledForCompany() {
            return this.notifRejected.is_enabled;
        },
        isSigningNotifEnabledForCompany() {
            return this.notifToSign.is_enabled;
        },
        isValidationNotifEnabledForCategory() {
            return this.requirementCategory.resource.programs[0]?.notifications?.some((notif) => notif.id === this.notifToValidate.id);
        },
        isRejectionNotifEnabledForCategory() {
            return this.requirementCategory.resource.programs[0]?.notifications?.some((notif) => notif.id === this.notifRejected.id);
        },
        isSigningNotifEnabledForCategory() {
            return this.requirementCategory.resource.programs[0]?.notifications?.some((notif) => notif.id === this.notifToSign.id);
        },
        ...mapState('stepperform', ['loading']),

        ...mapState('programs', [
            'programs',
            'programsLoaded',
        ]),
        requirementCategory: {
            set(requirementCategory) {
                this.$store.dispatch('stepperform/setItem', requirementCategory);
            },
            get() {
                if (this.$store.state.stepperform.item.company_requirement?.[0]?.type === 'dynamic_document') {
                    this.hasDynamicDocument = true;
                }
                return cloneDeep(this.$store.state.stepperform.item);
            },
        },

        getParticipantExecutor() {
            if (this.requirementCategory.display_survey) {
                return [{
                    company_user: {
                        id: 10000, // because of check in ParticipantList
                        firstname: this.translate('Survey recipient'),
                        lastname: '',
                    },
                }];
            }

            return this.participants.find(
                (participant) => CompanyResourceProgramParticipant.TYPE_EXECUTOR === participant.type,
            )?.role;
        },

        getPreparer() {
            return this.participants.find(
                (participant) => CompanyResourceProgramParticipant.TYPE_PREPARER === participant.type,
            )?.role;
        },

        getValidators() {
            return this.participants.filter((participant) => CompanyResourceProgramParticipant.TYPE_VALIDATOR === participant.type)
                .sort((a, b) => parseInt(a.execution_order, 10) - parseInt(b.execution_order, 10))
                .map((participant) => ({ ...participant.role, execution_order: participant.execution_order }));
        },

        getCategorySupervisors() {
            return this.requirementCategory.company_requirement_category_supervisor.map((supervisor) => supervisor.role);
        },

        getParticipantSigners() {
            return this.participants.filter((participant) => CompanyResourceProgramParticipant.TYPE_SIGNER === participant.type)
                .sort((a, b) => parseInt(a.execution_order, 10) - parseInt(b.execution_order, 10))
                .map((participant) => ({ ...participant.role, execution_order: participant.execution_order, disabled: this.isRoleUsedAsPdfFieldCompleter(participant.role) }));
        },

        getUnavailablesForSigning() {
            return this.participants.filter(
                (participant) => (CompanyResourceProgramParticipant.TYPE_SIGNER === participant.type
                    || CompanyResourceProgramParticipant.TYPE_EXECUTOR === participant.type),
            )
                .map((participant) => participant.role);
        },

        getUnavailableForValidator() {
            return this.participants.filter((participant) => CompanyResourceProgramParticipant.TYPE_VALIDATOR === participant.type)
                .map((participant) => participant.role);
        },

        /**
         * @returns {Array}
         */
        selectedPrograms() {
            const selectedIds = this.requirementCategory.resource.programs.map((resourceProgram) => resourceProgram.company_program_id);
            return this.programsOptions.filter((option) => selectedIds.includes(option.id));
        },
        programsOptions() {
            return this.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
        getRoleOptions() {
            return this.$store.state.roles.roles
                .filter((role) => Boolean(role.is_assignable_resource))
                .map((role) => ({
                    ...role,
                    company_role_id: role.id,
                })) ?? [];
        },
        getRoleOptionsWithoutEnrollee() {
            return this.getRoleOptions.filter((role) => role.name !== 'enrollee');
        },
        getRoleOptionsWithoutEnrolleeAndWithAsignedRoleDisabled() {
            return this.getRoleOptions.map(
                (role) => ({
                    disabled: this.isRoleUsedAsPdfFieldCompleter(role),
                    ...role,
                }),
            );
        },
        getAssociatedProgramsTooltip() {
            if (this.hasDynamicDocument && this.hasVariablesInDynamicDocument) {
                return this.translate('You cannot change the program of a requirement category containing a dynamic document populated with variables.');
            }

            return this.requirementCategory.resource.programs.length > 1
                ? this.translate('Dynamic documents are not available in multi-program mode.')
                : '';
        },
        hasVariablesInDynamicDocument() {
            if (!this.requirementCategory.company_requirement.length) {
                return false;
            }

            const docContainVariables = this.requirementCategory.company_requirement[0].dynamic_documents.filter((doc) => doc.variables.length > 0);
            return docContainVariables.length > 0;
        },
    },
    created() {
        if (this.requirementCategory.resource.programs.length > 0) {
            this.participants = this.requirementCategory.resource.programs[0].participants;
        }

        this.$store.dispatch('programs/fetchPrograms');
        this.$store.dispatch('roles/fetchRoles')
            .then(() => {
                const roleEnrollee = this.$store.state.roles.roles.find((role) => role.name === 'enrollee');
                if (this.participants.length === 0) {
                    this.addRecipients([roleEnrollee], CompanyResourceProgramParticipant.TYPE_EXECUTOR);
                }
            });

        this.is_signers_orderable = !this.participants
            ?.filter((p) => p.type === CompanyResourceProgramParticipant.TYPE_SIGNER)
            .every((p) => p.execution_order === 1);

        this.is_validators_orderable = !this.participants
            ?.filter((p) => p.type === CompanyResourceProgramParticipant.TYPE_VALIDATOR)
            .every((p) => p.execution_order === 1);
    },

    methods: {
        onRemoveRecipient(participantIndex, participantType) {
            this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                let idx = 0;
                const indexParticipantFind = resourceProgram.participants.findIndex((participant) => {
                    if (participantType === participant.type) {
                        if (idx === participantIndex) return true;
                        idx++;
                    }
                });
                resourceProgram.participants.splice(indexParticipantFind, 1);
            });

            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        addRecipients(roles, participantType) {
            const participants = roles
                .filter((role) => role !== null)
                .map((role) => ({
                    company_role_id: role?.id,
                    role,
                    company_role: role,
                    execution_order: 1,
                }));

            if (participantType) {
                const otherParticipant = this.participants.filter(
                    (participant) => participantType !== participant.type,
                );

                const newParticipants = participants.map((participant) => ({
                    ...participant,
                    type: participantType,
                }));

                this.participants = [...otherParticipant, ...newParticipants];

                return;
            }

            this.requirementCategory.company_requirement_category_supervisor = participants;

            const hasSupervisors = this.requirementCategory.company_requirement_category_supervisor.length;
            if (!hasSupervisors) {
                this.requirementCategory.is_notifiable = false;
            }

            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        isRoleUsedAsPdfFieldCompleter(role) {
            return this.requirementCategory.company_requirement
                .some((requirement) => requirement
                    .company_pdf_form_template?.company_pdf_form_template_field
                    .some((field) => field.completer_id === role.id));
        },

        addParticipant(type, canOrder = false) {
            const newParticipant = {
                type,
                execution_order: canOrder ? (this.participants.filter((p) => p.type === type).length + 1) : 1,
                role: { id: this.$Utils.uniqId() },
                company_role_id: null,
            };

            this.participants.push(newParticipant);
        },

        updateParticipant(params, type) {
            const participant = this.participants.find((participant) => participant.role.id === params.oldRole?.id && participant.type === type);
            participant.role = params.newRole;
            participant.company_role_id = params.newRole.id;
        },

        removeParticipant(role, type) {
            const participant = this.participants.find((participant) => participant.role.id === role?.id && participant.type === type);
            const index = this.participants.indexOf(participant);

            this.participants.splice(index, 1);

            this.setParticipantsOrders(type);
        },

        participantsOrderChanged(roles, type) {
            const typesParticipants = this.participants.filter(
                (participant) => participant.type === type,
            );

            const otherParticipants = this.participants.filter(
                (participant) => participant.type !== type,
            );

            const reOrderedParticipants = typesParticipants.map((participant) => {
                const matchingRole = roles.find((role) => role.id === participant.company_role_id);

                return {
                    ...participant,
                    execution_order: matchingRole.execution_order + 1,
                };
            });

            this.participants = null;
            this.participants = [...otherParticipants, ...reOrderedParticipants];
        },

        setIsNotifiable() {
            this.requirementCategory.is_notifiable = !this.requirementCategory.is_notifiable;
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        setValidationNotifForCategory() {
            if (this.isValidationNotifEnabledForCategory) {
                this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                    resourceProgram.notifications.splice(
                        resourceProgram.notifications.findIndex((notif) => notif.id === this.notifToValidate.id),
                        1,
                    );
                });
            } else {
                this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                    if (!resourceProgram.notifications) {
                        resourceProgram.notifications = [];
                    }
                    resourceProgram.notifications.push({ id: this.notifToValidate.id });
                });
            }
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        setRejectionForCategory() {
            if (this.isRejectionNotifEnabledForCategory) {
                this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                    resourceProgram.notifications.splice(
                        resourceProgram.notifications.findIndex((notif) => notif.id === this.notifRejected.id),
                        1,
                    );
                });
            } else {
                this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                    if (!resourceProgram.notifications) {
                        resourceProgram.notifications = [];
                    }
                    resourceProgram.notifications.push({ id: this.notifRejected.id });
                });
            }
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        setSigningNotifForCategory() {
            if (this.isSigningNotifEnabledForCategory) {
                this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                    resourceProgram.notifications.splice(
                        resourceProgram.notifications.findIndex((notif) => notif.id === this.notifToSign.id),
                        1,
                    );
                });
            } else {
                this.requirementCategory.resource.programs.forEach((resourceProgram) => {
                    if (!resourceProgram.notifications) {
                        resourceProgram.notifications = [];
                    }
                    resourceProgram.notifications.push({ id: this.notifToSign.id });
                });
            }
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        /**
         * When remove one program from selected list
         */
        onRemovePrograms(option) {
            this.requirementCategory.resource.programs = this.requirementCategory.resource.programs.filter((resourceProgram) => resourceProgram.company_program_id !== option.id);
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        /**
         * When select one program to add it on selected list
         */
        onSelectPrograms(option) {
            const programSelect = this.$store.state.programs.programs.find((program) => program.id === option.id);
            programSelect.name = this.oldLocalize(programSelect.locales, 'name');

            const role = this.$store.state.roles.roles.find((role) => role.name === 'enrollee');

            if (this.hasDynamicDocument) {
                this.requirementCategory.resource.programs = [];
            }

            this.requirementCategory.resource.programs.push({
                company_program_id: programSelect.id,
                is_enabled: true,
                program: programSelect,
                filters: [],
                participants: [{
                    company_role_id: role?.id,
                    role,
                    company_role: role,
                    type: CompanyResourceProgramParticipant.TYPE_EXECUTOR,
                }],
            });

            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        /**
         * When Saving model RequirementCategory (store / update)
         */
        onSave() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.$store.dispatch('stepperform/enableLoading');
                    this.participants.forEach(
                        (participant) => participant.id = null,
                    );

                    this.requirementCategory.resource.programs.forEach(
                        (program) => program.participants = this.participants,
                    );

                    api.configuration.requirementCategory
                        .update(this.requirementCategory.id, this.requirementCategory)
                        .then((res) => {
                            this.$store.dispatch('stepperform/setItem', res.data.data);
                            this.$emit('goTo', 'content');
                        })
                        .finally(() => {
                            this.$store.dispatch('stepperform/disableLoading');
                        });
                }
            });
        },

        setDisplayChatbot(value) {
            this.requirementCategory.display_chatbot = value;
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        setParticipantsOrders(type) {
            const typesParticipants = this.participants.filter((p) => p.type === type);
            const others = this.participants.filter((p) => p.type !== type);

            const canOrder = type === CompanyResourceProgramParticipant.TYPE_VALIDATOR ? this.is_validators_orderable : this.is_signers_orderable;

            this.participants = [
                ...others,
                ...typesParticipants.map((s, index) => ({ ...s, execution_order: canOrder ? (index + 1) : 1 /* Minimum is 1 */ })),
            ];
        },
    },
};
</script>
