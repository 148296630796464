<template>
    <div
        class="file-upload-wrapper"
        :data-cy="cypress"
    >
        <div :class="['file-upload', {'border-straight': hasFile && widthProgressBar > 0}]">
            <FilledBar
                v-if="hasFile"
                ref="filledBar"
                @on-edit="$emit('on-edit')"
                @file-deleted="$emit('file-deleted')"
            />
            <EmptyBar
                v-else
                :label="emptyLabel"
            />
        </div>
        <ProgressBar
            class="progress"
            :width="widthProgressBar"
        />
        <label v-if="showMediaLegend">
            <span class="sub-text">
                <t>File type:</t> {{ mediaLegend }}
            </span>
        </label>
        <label v-if="showFileSizeLegend && !value">
            <span class="sub-text">
                <t>Max size:</t> {{ Math.round((maxSize + Number.EPSILON) * 100) / 100 }} <t>MB</t>
            </span>
        </label>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';
import EmptyBar from './EmptyBar.vue';
import FilledBar from './FilledBar.vue';
import ProgressBar from './ProgressBar.vue';

export default {
    components: { EmptyBar, FilledBar, ProgressBar },

    mixins: [
        CypressMixin,
    ],

    provide() {
        return {
            uploadBar: this,
        };
    },

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        media: {
            type: Array,
            required: true,
        },
        extension: {
            type: Array,
            default: null,
        },
        maxSize: {
            type: Number,
            default: 20,
        },
        showMediaLegend: {
            type: Boolean,
            default: false,
        },
        showFileSizeLegend: {
            type: Boolean,
            default: false,
        },
        withEdit: {
            type: Boolean,
            default: false,
        },
        withTrash: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Object,
        },
        emptyLabel: {
            type: String,
            default: 'Add a file',
        },
    },

    data() {
        return {
            widthProgressBar: 0,
        };
    },

    computed: {
        hasFile() {
            return Boolean(this.value?.id);
        },

        mediaLegend() {
            const medias = {
                pdf: 'PDF',
                docx: 'docx',
                txt: 'txt',
                office: 'Office (word, excel, txt, csv...)',
                csv: 'CSV',
                image: 'Image (png, jpeg...)',
            };

            const string = this.media.map((m) => medias[m]);

            return string.join(', ');
        },
    },

    methods: {
        resetProgression() {
            this.widthProgressBar = 0;
        },
        destroy() {
            this.$refs.filledBar.destroy();
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.file-upload{
    background-color: white;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    padding: 1rem 1.6rem;
    font-weight: bold;

    &.border-straight {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.progress {
    margin-top: -1px; // marge négative pour que la bar de progression soit à l'intérieur du champ d'input
}
</style>
