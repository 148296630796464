<template>
    <div>
        <ProgramList
            :title="title"
            :subtitle="translate('Enrollees linked to your perimeter.')"
            :button-text="translate('Add a user')"
            :show-button="canCreateProgram"
            :type="'active'"
            :program-type="programType"
            :company-program="companyProgram"
            @on-button-clicked="createOnboardingClicked"
            @pagination-refreshed="liveProgramCount = $event.total"
        />

        <UserSelectionModal
            ref="selectUserModal"
            :company-program-id="parseInt($route.params.program_id)"
            @addExistingUser="openUsersModal"
            @create-new-invitation-session="openNewInvitationSessionModal"
        />

        <AddExistingUserModal
            ref="addExistingUserModal"
            :company-program-id="parseInt($route.params.program_id)"
            @userSelected="userSelected"
        />
    </div>
</template>

<script>
import ProgramList from '@/components/pages/program/lists/ProgramList.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import NewInvitationSessionModal from '@/components/pages/foc/multiple/NewInvitationSessionModal.vue';
import UserSelectionModal from './modals/UserSelectionModal.vue';
import AddExistingUserModal from './modals/AddExistingUserModal.vue';

export default {
    name: 'ProgramDetailLive',
    permissions: [
        'ModelCompanyUserProgram',
    ],

    components: {
        ProgramList,
        UserSelectionModal,
        AddExistingUserModal,
    },

    mixins: [
        ModalMixin,
    ],

    props: {
        programType: {
            type: String,
            default: '',
        },
        companyProgram: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            liveProgramCount: 0,
        };
    },

    computed: {
        canCreateProgram() {
            return this.$canCreate('ModelCompanyUserProgram');
        },
        title() {
            let programName = '';
            if (this.companyProgram?.name_translated) {
                programName = `${this.companyProgram.name_translated} : `;
            }

            return `${programName}${this.translate('Live programs | {count} live program | {count} live programs', { count: this.liveProgramCount })}`;
        },
    },

    methods: {
        searchChanged(search) {
            this.search = search;
        },
        createOnboardingClicked() {
            this.$refs.selectUserModal.open();
        },
        openUsersModal() {
            this.$refs.selectUserModal.close();
            this.$refs.addExistingUserModal.open();
        },
        openNewInvitationSessionModal() {
            this.$refs.selectUserModal.close();

            this.openCenterModal(
                NewInvitationSessionModal,
                {
                    programId: parseInt(this.$route.params.program_id, 10),
                },
                {},
                { clickToClose: false },
            );
        },
        userSelected(user) {
            this.$router.push({
                name: 'Enrolling',
                query: {
                    program_id: this.$route.params.program_id,
                    user_id: user.id,
                },
            });
        },
    },
};
</script>
