<template>
    <div class="container">
        <UploadBar
            v-for="(input,index) in inputList"
            :key="index"
            v-model="companyFiles[index]"
            class="upload-bar"
            :media="media ? media : ['office', 'pdf', 'image']"
            :show-media-legend="item.media ? true : false"
            :max-size="getMaxSize"
            :show-file-size-legend="item.file_max_size !== null"
            :disabled="disabled"
            :cypress="`${cypress}-${index}`"
            :is-requirement="isRequirement"
            @update:fileSize="updateFileSize"
            @onUploadEnd="$emit('onUploadEnd')"
            @onRemove="onRemove"
        />
        <div
            class="add-document"
            @click="addInput()"
        >
            <t>Add a file</t>
        </div>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';

export default {
    name: 'MultipleFilesUpload',
    mixins: [CypressMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
        media: {
            type: Array,
            required: true,
        },
        maxSize: {
            type: Number,
            default: 20,
        },
        showMediaLegend: {
            type: Boolean,
            default: false,
        },
        showFileSizeLegend: {
            type: Boolean,
            default: false,
        },
        isRequirement: {
            type: Boolean,
            default: false,
        },
        value: {},
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            inputList: [],
            companyFiles: this.item.company_files,
            totalFileSize: 0,
        };
    },

    computed: {
        getMaxSize() {
            if (this.maxSize) {
                return this.maxSize - this.totalFileSize;
            }
            return 20 - this.totalFileSize;
        },
    },

    watch: {
        companyFiles(newCompanyFiles) {
            // on ne revoie au component parent que les files qui n'ont pas été remove
            // Comment savoir si un file est remove ? => si un file est remove, alors son id est null
            newCompanyFiles = newCompanyFiles.filter((companyFile) => companyFile && companyFile.id !== null);

            this.$emit('update:company-files', newCompanyFiles);
        },
    },

    created() {
        this.addInput();
    },

    methods: {
        addInput() {
            this.inputList.push([]);
        },
        updateFileSize(v) {
            this.totalFileSize += v;
        },
        onRemove() {
            this.companyFiles = this.companyFiles.filter((file) => file !== null);

            this.$emit('onRemove');
        },
    },
};
</script>
<style lang="scss" src="./MultipleFilesUpload.scss" scoped />
