<template>
    <transition name="fade">
        <TranslatedForm
            v-if="item"
            v-slot="{editingLang}"
        >
            <form
                class="form-2 spaced"
                @submit.prevent=""
            >
                <HtFormInput
                    id="name"
                    v-model.trim="item.resource.translations.name[editingLang]"
                    v-validate.disable="'required|max:255'"
                    :label="translate('Name')"
                    :data-vv-as="translate('name')"
                    name="name"
                    cypress="item-name"
                    show-asterisk
                />

                <fieldset class="medium">
                    <HtFormEditor
                        :id="'description'"
                        v-model="item.resource.translations.description[editingLang]"
                        :name="'description'"
                        :label="translate('Description')"
                        :has-toolbar="false"
                        cypress="equipment-description"
                    />
                </fieldset>

                <HtProgramList
                    ref="htProgramListInput"
                    v-model="item.resource.programs[0].company_program_id"
                    :required="false"
                    :with-default="false"
                    :disabled="disabledProgramList"
                />

                <section v-if="isResourceTargeted">
                    <HtFormGroup
                        :label="translate('Filters')"
                        :description="translate('Add filters to decide who can access the resource')"
                    >
                        <HtFormCard>
                            <ProgramResourceFilters
                                v-if="item.resource.programs[0].company_program_id"
                                v-model="item.resource.programs[0].filters"
                                :program-id="item.resource.programs[0].company_program_id"
                            />
                            <div
                                v-else
                                class="sentence-filter"
                            >
                                <t>Select a program to display associated filters</t>
                            </div>
                        </HtFormCard>
                    </HtFormGroup>

                    <HtKeyDatesInputOrder
                        :end-label="translate('To deliver')"
                        :program-id="item.resource.programs[0].company_program_id"
                        :is-business-day.sync="item.resource.programs[0].is_business_day"
                        :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                        :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                        :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                        :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                        :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                    />
                </section>
            </form>

            <div class="modal-actions right">
                <Button
                    v-show="Boolean(id)"
                    :state="buttonState"
                    class="negative"
                    cypress="equipment-delete"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    :state="buttonState"
                    cypress="equipment-add"
                    @click="onValidate()"
                >
                    <t>{{ item.id ? 'Save' : 'Add' }}</t>
                </Button>
            </div>
        </TranslatedForm>
    </transition>
</template>

<script>
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import ResourceModalEditMixin from '@/mixins/ResourceModalEditMixin';
import ResourceKeyDateLabelMixin from '@/mixins/ResourceKeyDateLabelMixin';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import I18n from '@/modules/i18n/I18n';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'EquipmentEdit',
    components: {
        HtKeyDatesInputOrder,
        HtProgramList,
        HtFormCard,
        ProgramResourceFilters,
        TranslatedForm,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        ResourceModalEditMixin,
        ResourceKeyDateLabelMixin,
    ],

    inject: ['modal'],

    data() {
        return {
            item: null,
        };
    },

    computed: {
        isResourceTargeted() {
            return this.item && this.item.resource.programs.length > 0 && !!this.item.resource.programs[0].company_program_id;
        },

        labelTitle() {
            return I18n.translate(this.id ? 'Edit equipment' : 'Add equipment');
        },
    },

    methods: {
        async fetchModelFromId() {
            const equipment = (await this.$http.get(
                `configuration/equipments/${this.id}`,
            )).data.data;

            const isResourceTargeted = equipment.resource.programs.length > 0;
            if (!isResourceTargeted) {
                // default values for the targeting
                equipment.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            return equipment;
        },

        setDefaultOrderCompletion() {},

        createModelFromCollection() {
            this.item = {
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                },
            };
        },

        async createModelFromId() {
            this.item = await this.fetchModelFromId();
        },

        async duplicateModel() {
            this.item = await this.fetchModelFromId();
            this.item.id = undefined;
            this.item.resource.id = null;
            this.item.resource.programs[0].id = null;
            this.item.resource.programs[0].company_program_id = this.companyProgramId;
            this.item.resource.programs[0].company_program_key_date_id = null;
            this.item.resource.programs[0].filters = [];
            this.item.resource.programs[0].dependents = [];
            this.item.resource.programs[0].dependencies = [];
            this.item.resource.programs[0].participants = [];
        },

        async onValidate() {
            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                return;
            }
            this.buttonState = 'loading';

            const resourceToSave = cloneDeep(this.item);
            if (!resourceToSave.resource.programs[0].company_program_id) {
                resourceToSave.resource.programs = [];
            }

            await this.$http.post(
                `configuration/equipments/${this.item.id || ''}`,
                resourceToSave,
            );
            this.buttonState = 'idle';
            this.$emit('onSave');

            this.modal.close();
        },

        async onDelete() {
            const shouldDelete = await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this equipment? Once you click on delete, you will no longer be able to access this equipment.') });
            if (!shouldDelete) {
                return;
            }
            this.buttonState = 'loading';
            await this.$http.delete(`configuration/equipments/${this.item.id}`);
            this.buttonState = 'idle';
            this.$emit('onDelete');
            this.modal.close();
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-library .item-content{
    min-height: 198px !important;
}
</style>
