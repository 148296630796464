<template>
    <div class="wrapper">
        <header>
            <h4>
                <t :count="userSurveys.length">
                    {count} answer | {count} answers
                </t>
            </h4>
        </header>
        <main>
            <AllResultsRow
                :class="['row-item', {'clickable': userSurveys.length > 0}]"
                :no-surveys="userSurveys.length === 0"
                :selected="currentUserSurveyId === null"
                @on-click="onClickAllResults"
            />
            <ParticipantItem
                v-for="userSurvey in userSurveys"
                v-if="userSurvey"
                :key="userSurvey.item.id"
                class="row-item clickable"
                :selected="userSurvey.occurrences.map(o => o.id).includes(currentUserSurveyId)"
                :is-targeting-program="isTargetingProgram"
                :participant-info="userSurvey.item.participant"
                :enrollee-info="userSurvey.item.enrollee"
                @on-click="onSelectUserSurvey(userSurvey.item.id, userSurvey.occurrences)"
            />
        </main>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import ParticipantItem from './ParticipantItem/ParticipantItem.vue';
import AllResultsRow from './AllResultsRow.vue';

export default {
    name: 'SurveyFormResultsParticipants',
    components: { HtSearchField, ParticipantItem, AllResultsRow },
    props: {
        userSurveys: {
            type: Array,
            required: true,
        },
        isTargetingProgram: {
            type: Boolean,
            default: false,
        },
        currentUserSurveyId: {
            type: Number,
            required: false, // allow null
        },
    },
    methods: {
        onClickAllResults() {
            this.$emit('on-click-all-results');
        },
        onSelectUserSurvey(id, occurrences) {
            this.$emit('onSelectUserSurvey', id, occurrences);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.wrapper {
    padding: 1.5rem;
    background: $grey-blue;
    border: 1px solid $grey-ultra-light;
    border-radius: $border-radius-5;

}
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.row-item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
</style>
