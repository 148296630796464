export default {
    props: {
        /**
         * answer's label
         */
        answerLabel: {
            type: String,
            required: true,
        },
        /**
         * answer percent
         */
        percent: {
            type: Number,
            required: false, // if user is selected
        },
        noUserSurveySelected: {
            type: Boolean,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
};
