import Vue from 'vue';

/**
 * @typedef {{data: {data: Role}}} RoleResourceResponse
 * @typedef {{data: {data: Role[]}}} RoleCollectionResponse
 */

export default {
    /**
    *
    * @returns {Promise<RoleCollectionResponse>}
   */
    getRoles() {
        return Vue.prototype.$http.get('configuration/roles');
    },
    get(id) {
        return Vue.prototype.$http.get(`configuration/roles/${id}`);
    },
    getUsersSearch(roleId, search) {
        return Vue.prototype.$http.get(`configuration/roles/users-search?exclude_role_id=${roleId}&search=${search}`);
    },
};
