<template>
    <div
        ref="listMessage"
        class="list-message"
        :class="{'list-empty':sortMessageCollection.length <= 0}"
    >
        <ChatListMessageItem
            v-for="(message, index) in sortMessageCollection"
            :key="`message-item-${message.id}`"
            :display-avatar="display(index, 'avatar')"
            :display-date="display(index, 'date')"
            :company-chat-channel-message="message"
            :all-channel-message="channelMessageObject.messages"
            @scrollToBottom="scrollToBottom"
            @scrollToTop="scrollToTop"
        />
        <ChatAIListSuggestion
            v-if="isAiBotChannel && hasAISuggestions"
            @on-suggestion-click="$emit('on-suggestion-click', $event)"
        />
        <div class="list-message-mobile-spacing" />
        <template>
            <div
                v-if="isAiBotChannel === false"
                class="list-message-placeholder"
            >
                <img
                    class="list-message-placeholder-image"
                    src="@/assets/images/welcome1.svg"
                >
                <div class="list-message-placeholder-text">
                    <div
                        class="list-message-placeholder-text-title"
                    >
                        <t>
                            It's really quiet here ...
                        </t>
                    </div>
                    <br>
                    <div>
                        <t>
                            Send the first message to start the conversation !
                        </t>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="list-message-placeholder"
            >
                <img
                    class="list-message-placeholder-image"
                    src="@/assets/images/welcome1.svg"
                >
                <div class="list-message-placeholder-text">
                    <div
                        class="list-message-placeholder-text-title"
                    >
                        <t>
                            Hello!
                        </t>
                    </div>
                    <br>
                    <div>
                        <t>
                            I am your virtual assistant, dedicated to helping you with all your work-related questions.
                        </t>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import CompanyChatChannelMessageCollection from '@/models/CompanyChatChannelMessageCollection';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import ChatAIListSuggestion from '@/components/chat/ChatAIListSuggestion.vue';
import ChatListMessageItem from './ChatListMessageItem.vue';

export default {
    name: 'ChatListMessage',
    shared: {
        socket: {
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
            channelMessageCollection: {
                type: Object,
                default: {},
            },
        },
    },
    components: {
        ChatAIListSuggestion,
        ChatListMessageItem,
    },
    props: {
        isAiBotChannel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            channels: {},
            company: this.shared.session.company,
            channelCollection: this.shared?.socket?.channelCollection,
        };
    },
    computed: {
        isAIEnabled() {
            const settings = this.shared.session.company.company_knowledge_settings;

            return settings.is_ai_feature_enabled === true
                && settings.is_ai_bot_enabled === true;
        },
        hasHumanMessages() {
            if (!this.isAIEnabled) return false;
            if (!this.sortMessageCollection) return false;

            return this.sortMessageCollection.filter((message) => message.company_user_id !== this.shared.session.company.ai_bot_company_user_id).length > 0;
        },
        hasAISuggestions() {
            if (!this.isAIEnabled) return false;

            return !this.hasHumanMessages;
        },
        channelMessageObject() {
            return this.shared.socket.channelMessageCollection[this.channelActiveId];
        },
        channelActive() {
            if (!this.shared.socket.channelCollection) return null;

            return this.shared.socket.channelCollection.channelActive;
        },
        channelActiveId() {
            if (!this.channelActive) return null;
            return this.channelActive.id;
        },
        sortMessageCollection() {
            if (!this.channelActiveId && !this.channelMessageObject) return [];

            return this.channelMessageObject.messages.sort((a, b) => {
                const dateA = this.$Utils.moment(a.created_at).utc();
                const dateB = this.$Utils.moment(b.created_at).utc();
                return dateA.isSame(dateB) ? a.id - b.id : dateA - dateB;
            });
        },
    },
    watch: {
        channelActiveId() {
            this.setChannel();
            this.getMessages({ scrollToBottom: true });
        },
    },
    created() {
        this.setChannel();
        this.getMessages({ scrollToBottom: true });
    },
    mounted() {
        this.$refs.listMessage.addEventListener('scroll', () => {
            if (this.$refs.listMessage.scrollTop === 0) {
                this.getMessages();
            }
        });
    },
    methods: {
        setChannel() {
            if (this.channelActiveId !== null && !this.channelMessageObject) {
                this.$set(this.shared.socket.channelMessageCollection, this.channelActiveId, {
                    page: 1,
                    messages: [],
                    loading: false,
                    loadingEnd: false,
                    loadingStart: this.$Utils.moment().format('YYYY-MM-DD HH:mm:ss'),
                });
            }
        },
        getCompanyChannelMessageCollection() {
            return new CompanyChatChannelMessageCollection([
                'id', 'type', 'text', 'is_html', 'company_user_opentok_archive_id',
                'company_user_id', 'company_chat_channel_id', 'resource', 'resource_id', 'created_at',
            ]).with({
                files: (query) => {
                    query.select(UtilsCompanyFile.allowedMinimumFields().concat(['detected_mime_type']));
                },
                companyUser: (query) => {
                    query.select([
                        'id', 'firstname', 'lastname', 'image',
                    ]);
                },
                companyUserOpentokArchive: (query) => {
                    query.select([
                        'id', 'url', 'archive_id',
                    ]);
                },
                companyUserSurvey: (query) => {
                    query.select([
                        'id', 'company_survey_id',
                    ]);
                    query.with({
                        companyUserProgramTask: (query) => {
                            query.select([
                                'id',
                                'status',
                            ]);
                        },
                        companySurvey: (query) => {
                            query
                                .select([
                                    'id',
                                ])
                                .with({
                                    resource: (query) => {
                                        query
                                            .select([
                                                'id', 'name',
                                            ]);
                                    },
                                });
                        },
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname'])
                                .with({
                                    companyLanguage: (query) => {
                                        query.select(['id', 'key']);
                                    },
                                });
                        },
                    });
                },
                companyUserSurveyQuestionAnswer: (query) => {
                    query.select([
                        'id', 'company_survey_question_answer_id', 'text', 'company_survey_question_id', 'company_file_id',
                    ]);
                    query.with({
                        companySurveyQuestionAnswer: (query) => {
                            query.select([
                                'id', 'order', 'company_entity_value_id',
                            ]);
                            query.with({
                                value: (query) => {
                                    query.select([
                                        'id', 'resourceable_id', 'resourceable_type',
                                    ]).with({
                                        custom: (query) => {
                                            query.select([
                                                'id', 'name',
                                            ]);
                                        },
                                    });
                                },
                                companySurveyQuestionAnswerLocale: (query) => {
                                    query.select([
                                        'id', 'language_key', 'text',
                                    ]);
                                },
                            });
                        },
                        companyFile: (query) => {
                            query.select([
                                'id', 'original_name',
                            ]);
                        },
                    });
                    query.with({
                        companySurveyQuestion: (query) => {
                            query.select([
                                'id', 'type', 'subtype', 'params',
                            ]);
                        },
                    });
                },
                companyUserSurveyExtra: (query) => {
                    query.select([
                        'id', 'company_survey_extra_id',
                    ]);
                    query.with({
                        companySurveyExtra: (query) => {
                            query.select([
                                'id',
                            ]);
                            query.with({
                                companySurveyExtraLocale: (query) => {
                                    query.select([
                                        'id', 'language_key', 'text', 'company_survey_extra_id',
                                    ]);
                                },
                            });
                        },
                    });
                },
                companyUserSurveyQuestion: (query) => {
                    query.select([
                        'id', 'company_survey_question_id', 'is_skipped', 'status', 'company_user_participant_id', 'company_user_id', 'company_user_survey_id',
                    ])
                        .with({
                            companyUserSurveyQuestionAnswer: (query) => {
                                query
                                    .select([
                                        'id', 'company_survey_question_answer_id', 'text', 'company_file_id',
                                    ])
                                    .with({
                                    });
                            },
                        })
                        .with({
                            companySurveyQuestion: (query) => {
                                query.select([
                                    'id', 'type', 'subtype', 'is_mandatory', 'params', 'resourceable_id', 'resourceable_type',
                                ]).with({
                                    companySurveyQuestionLocale: (query) => {
                                        query.select([
                                            'id', 'language_key', 'text',
                                        ]);
                                    },
                                    companySurveyQuestionAnswer: (query) => {
                                        query.select([
                                            'id', 'order', 'company_entity_value_id',
                                        ]);
                                        query.with({
                                            value: (query) => {
                                                query.select([
                                                    'id',
                                                    'resourceable_id',
                                                    'resourceable_type',
                                                ]).with({
                                                    custom: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                        ]);
                                                    },
                                                });
                                            },
                                            companySurveyQuestionAnswerLocale: (query) => {
                                                query.select([
                                                    'id', 'language_key', 'text',
                                                ]);
                                            },
                                        });
                                    },
                                });
                            },
                        });
                },
                companyUserNudge: (query) => {
                    query.select([
                        'id', 'company_nudge_id', 'message', 'company_user_initiator_id',
                        'company_user_id', 'company_user_recipient_id', 'status',
                    ]);
                    query.with({
                        companyUserProgramTask: (query) => {
                            query.select([
                                'id', 'status', 'resource', 'resource_id',
                            ]);
                        },
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname'])
                                .with({
                                    companyLanguage: (query) => {
                                        query.select(['id', 'key']);
                                    },
                                });
                        },
                        companyUserInitiator: (query) => {
                            query.select([
                                'id', 'firstname', 'lastname', 'image',
                            ]);
                        },
                        companyUserRecipient: (query) => {
                            query.select([
                                'id', 'firstname', 'lastname', 'image',
                            ]);
                        },
                        companyNudge: (query) => {
                            query.select([
                                'id',
                            ]);
                            query.with({
                                resource: (query) => {
                                    query.select([
                                        'name',
                                    ]);
                                },
                            });
                        },
                    });
                },
            }).orderBy([
                ['created_at', 'desc'], ['id', 'desc'],
            ]);
        },
        display(index, type) {
            if (
                (index === this.sortMessageCollection.length - 1 && type === 'avatar')
                || (index === 0 && type === 'date')
            ) return true;
            const message1 = this.sortMessageCollection[index];
            const message2 = this.sortMessageCollection[type === 'avatar' ? index + 1 : index - 1];

            if (message1.company_user_id !== message2.company_user_id) {
                return true;
            }

            const date1 = this.$Utils.moment(message1.created_at);
            const date2 = this.$Utils.moment(message2.created_at);

            return date1.isSame(date2, 'minute') === false;
        },
        getMessages(options = {}) {
            if (
                this.channelActiveId === null
                || this.channelMessageObject.loading
                || this.channelMessageObject.loadingEnd
                || (this.channelMessageObject.page > 1 && this.$refs.listMessage.scrollTop !== 0)
            ) return;

            this.channelMessageObject.loading = true;

            const where = [
                ['company_chat_channel_id', '=', this.channelActiveId],
            ];

            const perPage = 10;
            const { page } = this.channelMessageObject;

            const { channelActiveId } = this;

            this.getCompanyChannelMessageCollection().where(where).paginate({ perPage, page }).then((data) => {
                if (data.models.length < perPage) {
                    this.shared.socket.channelMessageCollection[channelActiveId].loadingEnd = true;
                }

                this.shared.socket.channelMessageCollection[channelActiveId].messages = [
                    ...data.models,
                    ...this.shared.socket.channelMessageCollection[channelActiveId].messages,
                ];

                this.shared.socket.channelMessageCollection[channelActiveId].page += 1;
                this.shared.socket.channelMessageCollection[channelActiveId].loading = false;

                const previousHeight = this.$refs.listMessage.scrollHeight;

                if (options.scrollToBottom === true) {
                    this.$nextTick(() => {
                        this.$refs.listMessage.scrollTo(0, this.$refs.listMessage.clientHeight);
                    });
                } else {
                    this.$nextTick(() => {
                        this.$refs.listMessage.scrollTo(0, this.$refs.listMessage.scrollHeight - previousHeight);
                    });
                }
            });
        },
        clearThinkingMessages() {
            this.shared.socket.channelMessageCollection[this.channelActiveId].messages = this.shared.socket.channelMessageCollection[this.channelActiveId].messages.filter((message) => message.type !== 'thinking');
        },
        scrollToBottom() {
            this.$refs.listMessage.scrollTo(0, this.$refs.listMessage.scrollHeight);
        },
        scrollToTop() {
            this.$refs.listMessage.scrollTo({ top: 0 });
        },
    },
    sockets: {
        default: {
            onDirectMessage(data) {
                if (!this.shared.socket.channelMessageCollection[data.channelId]) return;
                // Création du message
                const companyChatChannelMessage = this.getCompanyChannelMessageCollection().new();

                // Hydratation du message
                companyChatChannelMessage.fill(data.message);

                // Nettoyage des thinkings
                this.clearThinkingMessages(data);

                // Ajout du message dans la liste
                this.shared.socket.channelMessageCollection[data.channelId].messages.push(companyChatChannelMessage);

                // Scroll en bas de la page
                this.$nextTick(() => {
                    if (this.channelActiveId === data.channelId) {
                        this.scrollToBottom();
                    }
                });
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.list-message {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    height: 0;

    @media (max-width: $phone) {
        padding-bottom: 100px;

       .list-message-mobile-spacing {
         min-height: 50px;
       }
    }

    // Needed to move the simple question in the middle of the chat
    position: relative;

    &-placeholder {
        display: none;
    }

    &.list-empty {
        padding: unset;
        .list-message-placeholder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #F5F8F9;

            &-text {
                text-align: center;

                &-title {
                    font-weight: bold;
                    font-size: 1.2em;
                }
            }

            &-image {
                display: block;
            }
        }
    }
}
</style>
