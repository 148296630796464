export default {
    HOLDING_USERS: 'holding/users',
    HOLDING_COMPANIES: 'holding/companies',
    HOLDING_LOGIN: 'holding/login/{company}',
    HOLDING_USERS_INDEX: 'holding/users/{user}',
    HOLDING_COMPANY_LIST: 'holding/company_list',
    HOLDING_USERS_PASSWORD: 'holding/users/{user}/password',

    CONFIGURATION_ENTITIES: 'configuration/entities',
    CONFIGURATION_JOB_POSITIONS: 'configuration/job-positions',
    CONFIGURATION_DEPARTMENTS: 'configuration/departments',
    CONFIGURATION_CONTRACTS: 'configuration/contracts',
    CONFIGURATION_OFFICES: 'configuration/offices',
    CONFIGURATION_CUSTOMS: 'configuration/customs',

    CONFIGURATION_PROGRAMS: 'configuration/programs',
    CONFIGURATION_PROGRAMS_SIMULATE: 'configuration/programs/{program}/simulate',
    CONFIGURATION_PROGRAM_SHOW: 'configuration/programs/{program}',
    CONFIGURATION_EMAILS_DUPLICATION_FILE: 'configuration/emails/{email}/duplicate-file/{file}',
    CONFIGURATION_EVENTS_DUPLICATION_FILE: 'configuration/events/{event}/duplicate-file/{file}',
    CONFIGURATION_DOCUMENTS_DUPLICATION_FILE: 'configuration/documents/{document}/duplicate-file/{file}',
    CONFIGURATION_TRAININGS_DUPLICATION_FILE: 'configuration/trainings/{training}/duplicate-file/{file}',

    PERMISSIONS: 'miscellaneous/permissions',

    CAROUSELS: 'carousel/',
    CAROUSELS_BY_COMPANY: 'carousel/bycompany',
    CAROUSEL_ITEMS: 'carousel/item',
};
