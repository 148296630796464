<template>
    <div class="questions-tab">
        <div
            v-if="isAIEnabled && $can('ModelCompanySurvey') && !isCompleted"
            class="empty"
        >
            <div
                class="questions-ia-new mb-5"
            >
                <AIForm
                    ref="aiForm"
                    :settings="{maxAIContentLimit: 10, hasToggleClearContent: true, defaultClearContentValue: shouldClearContent}"
                    :suggestions="suggestions"
                    class="ai-form"
                    :is-loading="isAILoading"
                    @onAskAI="askAI"
                    @onClearContentToggle="onAIClearContentToggle"
                />

                <div
                    v-if="survey.contents.length > 0"
                    class="clear-content-container"
                />
            </div>
        </div>
        <div
            v-if="survey.contents.length === 0"
        >
            <div
                v-show="!isAILoading"
                class="empty"
            >
                <p>
                    <t>There is nothing to show for now...</t>
                </p>
                <p>
                    <t>Add a first question to start</t>
                    😃
                </p>
                <div
                    data-cy="survey-question-add"
                    :class="['questions-tab-new', {'error-question': questions.length === 0, 'opacity-50': isAILoading}]"
                    @click="openAddQuestion"
                >
                    <FontAwesomeLayers class="button-add">
                        <FontAwesomeIcon
                            size="2x"
                            icon="circle"
                            transform="left-9"
                            class="icon-circle"
                        />
                        <FontAwesomeIcon
                            size="2x"
                            icon="plus"
                            transform="left-8 shrink-10"
                            class="plus"
                        />
                    </FontAwesomeLayers>

                    <t>Add a question</t>
                </div>
            </div>
            <div
                v-show="isAILoading"
                class="text-center"
            >
                <IconLoading class="mb-4" />
                <t>Please wait for the questions to be generated. This may take a few minutes.</t>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-4">
                    <div class="sticky">
                        <div
                            class="mb-10 wrapper-language-selector"
                            :class="{'opacity-50': isAILoading}"
                        >
                            <HtFormMultiSelect
                                id="questionLanguageSelector"
                                v-model="currentLanguage"
                                :options="companyLanguage.enabled"
                                name="questionLanguageSelector"
                                is-single
                                :is-searchable="false"
                                :allow-empty="false"
                                class="mb-20"
                                :disabled="isAILoading"
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                >
                                    <div class="d-flex align-items-center">
                                        <div class="rounded-flag">
                                            <country-flag
                                                :country="option.codeFlag"
                                                size="big"
                                            />
                                        </div>

                                        <span class="ml-10">{{ option.name }}</span>
                                    </div>
                                </template>

                                <template
                                    slot="option"
                                    slot-scope="{ option }"
                                >
                                    <div class="d-flex align-items-center">
                                        <div class="rounded-flag">
                                            <country-flag
                                                :country="option.codeFlag"
                                                size="big"
                                            />
                                        </div>

                                        <span class="ml-10">{{ option.name }}</span>
                                    </div>
                                </template>
                            </HtFormMultiSelect>
                        </div>

                        <SurveyFormQuestionsDraggable
                            v-model="survey.contents"
                            :current-language="currentLanguage"
                            :is-completed="isCompleted"
                            @add-question="openAddQuestion"
                        />

                        <div
                            v-if="isAILoading"
                            class="text-center"
                        >
                            <IconLoading
                                class="mb-5"
                            />
                            <t>Please wait for the questions to be generated. This may take a few minutes.</t>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 offset-md-1">
                    <SurveyListContent
                        v-model="survey.contents"
                        :current-language="currentLanguage"
                        :is-completed="isCompleted"
                        :is-conversational="survey.is_conversational"
                        :is-recurrent="isRecurrent"
                    />

                    <div
                        v-if="$can('ModelCompanySurvey') && !isCompleted"
                        :class="['questions-tab-new full', {'error-question': questions.length === 0}]"
                        @click="openAddQuestion"
                    >
                        <FontAwesomeLayers class="button-add">
                            <FontAwesomeIcon
                                size="2x"
                                icon="circle"
                                transform="left-9"
                                class="icon-circle"
                            />
                            <FontAwesomeIcon
                                size="2x"
                                icon="plus"
                                transform="left-8 shrink-10"
                                class="plus"
                            />
                        </FontAwesomeLayers>
                        <t>Add a question</t>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="$canUpdate('ModelCompanySurvey')"
            class="buttons"
        >
            <Button
                class="negative"
                :disabled="isAILoading"
                @click="$emit('goTo', 'targets')"
            >
                <t>Back</t>
            </Button>

            <Button
                v-if="!isCompleted"
                :state="buttonState"
                cypress="survey-question-submit"
                :disabled="isAILoading"
                @click="onSave"
            >
                <t>Save</t>
            </Button>
        </div>

        <Modalable
            ref="chooseNewQuestion"
            class="modalable-1 medium"
            :mode="2"
        >
            <SurveyFormQuestionsAdd
                :is-enroll-program="isEnrollProgram"
                :is-conversational="survey.is_conversational"
                @onAddContent="onAddContent"
            />
        </Modalable>
    </div>
</template>

<script>

import CompanySurvey from '@/models/CompanySurvey';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import HtProgramType from '@/models/HtProgramType';
import validationFormEventBus from '@/eventBus/validationFormEventBus';
import { mapState } from 'vuex';
import Modalable from '@/components/globals/Modalable.vue';
import Button from '@/components/globals/Button.vue';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import api from '@/store/api';
import IconLoading from '@/components/icons/IconLoading.vue';
import AISurveyService from '@/services/AISurveyService';
import AIForm from '@/components/globals/AI/AIForm.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import cloneDeep from 'lodash.clonedeep';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import UnauthorizedError from '@/services/Errors/UnauthorizedError';
import SurveyFormQuestionsDraggable from './SurveyFormQuestionsDraggable.vue';
import SurveyFormQuestionsAdd from './SurveyFormQuestionsAdd.vue';
import SurveyListContent from './SurveyListContent.vue';

export default {
    name: 'SurveyFormQuestions',
    components: {
        HtFormSwitch,
        AIForm,
        IconLoading,
        HtFormMultiSelect,
        Button,
        Modalable,
        SurveyFormQuestionsDraggable,
        SurveyListContent,
        SurveyFormQuestionsAdd,
    },
    inject: ['$validator'],

    shared: {
        session: {},
    },

    computed: {
        ...mapState('stepperform', ['loading']),
        survey: {
            set(survey) {
                this.$store.dispatch('stepperform/setItem', survey);
            },
            get() {
                return this.$store.state.stepperform.item;
            },
        },

        isAIEnabled() {
            const settings = this.shared.session.company.company_knowledge_settings;

            return settings.is_ai_feature_enabled === true
                && settings.is_ai_bot_enabled === true;
        },

        languages() {
            return this.companyLanguage.enabled.map((companyLanguage) => companyLanguage.key).join(',');
        },

        questions() {
            return this.survey.contents.filter((item) => item.resource === CompanySurveyContent.RESOURCE_QUESTION);
        },

        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },

        isCompleted() {
            return this.survey.status === CompanySurvey.STATUS_COMPLETED;
        },

        isEnrollProgram() {
            const slug = this.survey.resource.programs[0]?.program?.type?.slug;

            return slug && slug !== HtProgramType.SLUG_DEFAULT;
        },

        isRecurrent() {
            return !!this.survey.resource.programs[0]?.recurrence_frequence_value || false;
        },
    },

    methods: {
        openAddQuestion() {
            if (this.isAILoading) {
                return;
            }

            this.$refs.chooseNewQuestion.open();
        },

        scrollToBottom() {
            this.$nextTick(() => {
                const appElement = document.getElementById('app');
                appElement.scrollTop = appElement.scrollHeight;
            });
        },

        processAIResponse(newContent) {
            this.addContentToSurvey(newContent);

            this.scrollToBottom();
        },

        onAIClearContentToggle(value) {
            this.shouldClearContent = value;
        },

        async askAI(params) {
            if (this.shouldClearContent) {
                this.survey.contents = [];
            }

            this.isAILoading = true;

            try {
                const aiService = new AISurveyService(this.$env, RtwSharedSpace.store.session.capiToken);

                await aiService.ask(params.input, params.tone, params.maxContent, params.languages, this.processAIResponse);
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    this.$Auth.logOut();

                    this.$router.push('/login');

                    return;
                }

                console.error('Error', error);
            } finally {
                if (this.$refs.aiForm) {
                    this.isAILoading = false;
                }
            }
        },

        onAddContent(content, type, subtype = null, params = []) {
            const newContent = {
                order: this.survey.contents.length,
                next_content_order: null,
            };

            const translations = {
                text: {},
            };

            if (content === CompanySurveyContent.RESOURCE_EXTRA) {
                newContent.resource = CompanySurveyContent.RESOURCE_EXTRA;
                newContent.extra = {
                    translations,
                };
            } else {
                newContent.resource = CompanySurveyContent.RESOURCE_QUESTION;
                newContent.question = {
                    type,
                    subtype,
                    params,
                    translations,
                    is_mandatory: false,
                    resourceable_id: null,
                    resourceable_type: null,
                    answers: [],
                };
            }

            this.addContentToSurvey(newContent);

            this.scrollToBottom();
        },

        addContentToSurvey(content) {
            const clone = cloneDeep(this.survey);

            this.survey = {
                ...clone,
                contents: [...clone.contents, content],
            };
        },

        onSave() {
            this.currentLanguage = this.shared.session.company.company_language;

            this.$nextTick(() => {
                this.$validator.validateAll().then((result) => {
                    if (result === true) {
                        if (this.questions.length === 0) {
                            this.$Notifier('App').showError(this.translate('You need to have at least one question '));

                            return;
                        }

                        const hasAnswer = this.questions
                            .filter((item) => CompanySurveyQuestion.CHOICES_ANSWERS.includes(item.question.type))
                            .filter((item) => item.question.subtype !== CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_NPS)
                            .every((item) => item.question.answers.length > 0);

                        if (hasAnswer === false) {
                            this.$Notifier('App').showError(this.translate('You need to have at least one answer '));

                            return;
                        }

                        const requirementsQuestion = this.questions
                            .filter((item) => item.question.type === CompanySurveyQuestion.TYPE_REQUIREMENT)
                            .map((item) => item.question.resourceable_id);

                        if (this.$Utils.hasDuplicate(requirementsQuestion)) {
                            this.$Notifier('App').showError(this.translate('You can\'t use multiple times the same requirements '));

                            return;
                        }

                        this.$store.dispatch('stepperform/enableLoading');

                        api.configuration.surveys
                            .update(this.survey.id, this.survey)
                            .then((res) => {
                                this.$store.dispatch('stepperform/setItem', res.data.data);
                                this.$store.dispatch('stepperform/disableLoading');

                                this.$emit('goTo', 'planning');
                            });
                    } else {
                        validationFormEventBus.$emit('validate-form');
                        this.$Utils.scrollToFirstError(this);
                    }
                });
            });
        },
    },

    data() {
        return {
            token: null,
            mode: null,
            isAILoading: false,
            shouldClearContent: false,
            currentLanguage: this.shared.session.company.company_language,
            companyLanguage: this.shared.session.company.company_languages,
            currentSuggestion: null,
            suggestions: [
                { value: this.translate('Job interview') },
                { value: this.translate('Annual performance evaluation') },
            ],
        };
    },
};
</script>

<style lang="scss">
    .opacity-50 {
      opacity: 50%;
    }

    .wrapper-language-selector {
        .multiselect {
            width: 100%;
            max-height: 48px;

            .multiselect__tags {
                padding: 0 40px 0 15px;
            }

            .multiselect__select {
                top: 0;
                padding: 0 8px;
                height: 48px;

                &::before {
                    top: 33%;
                }
            }

            .multiselect__option {
                padding-top: calc((48px - 32px) / 2);
                padding-bottom: calc((48px - 32px) / 2);
            }
        }
    }
</style>

<style lang="scss" scoped src="./SurveyFormQuestions.scss"></style>
