<template>
    <div>
        <div class="question-input">
            <textarea
                :ref="`question-title-${_uid}`"
                v-model="question.translations.text[currentLanguage.key]"
                v-validate.disable="isDefaultLangSelected ? 'required' : ''"
                :placeholder="getPlaceholder"
                class="question-input-title"
                :data-vv-name="`question-${_uid}`"
                :name="`question-${_uid}`"
                :data-vv-as="translate(`question title`)"
                :data-cy="`survey-question-title-${question.type}-${question.subtype}`"
                :readonly="!$canUpdate('ModelCompanySurvey')"
                @input="editQuestion"
            />

            <FontAwesomeLayers
                v-if="$canUpdate('ModelCompanySurvey') && !isCompleted"
                class="question-input-button"
                @click="deleteContent"
            >
                <FontAwesomeIcon
                    size="2x"
                    icon="circle"
                    class="circle"
                />
                <FontAwesomeIcon
                    size="2x"
                    :icon="['far', 'trash-alt']"
                    transform="right-1 shrink-8"
                    class="trash-icon"
                />
            </FontAwesomeLayers>
        </div>

        <div
            v-for="(errorMessage, index) in errors.collect(`question-${_uid}`)"
            :key="`question-error-${index}`"
            class="error-container-item"
        >
            {{ errorMessage }}
        </div>

        <div class="question-mandatory">
            <HtFormSwitch
                :id="`toggle-mandatory-question-${_uid}`"
                v-model="question.is_mandatory"
                :disabled="isDisabled"
                :show-optional="false"
                :label="translate('Mandatory')"
                :name="`toggle-mandatory-question-${_uid}`"
                class="my-4"
            />

            <HtFormSwitch
                v-if="isNotConversational"
                :id="`toggle-has-comment-question-${_uid}`"
                v-model="question.has_comment"
                :disabled="isDisabled"
                :show-optional="false"
                :label="translate('Has comment')"
                :name="`toggle-has-comment-question-${_uid}`"
                class="my-4"
            />

            <HtFormSwitch
                v-if="isNotConversational && isRecurrent"
                :id="`toggle-show-previous-occurrence-question-${_uid}`"
                v-model="question.show_previous_occurrence"
                :disabled="isDisabled"
                :show-optional="false"
                :label="translate('Show previous occurrence')"
                :name="`toggle-show-previous-occurrence-question-${_uid}`"
                class="my-4"
            />

            <div
                v-if="canImportAnswers(question.type)"
                class="import-container branding-color"
            >
                <span @click="onOpenImportAnswers"><t>Import answers</t></span>
            </div>
        </div>

        <HtFormSwitch
            v-if="canBranch && !isLastItem"
            :id="`toggle-next-question-${_uid}`"
            :value="nextQuestionDependsOnAnswer"
            :show-optional="false"
            :disabled="!$canUpdate('ModelCompanySurvey')"
            :on-label="translate('Set up different questions depending on the answers')"
            :off-label="translate('Set up different questions depending on the answers')"
            :name="`toggle-next-question-${_uid}`"
            class="my-4"
            @input="setNextQuestionDependsOnAnswer"
        />

        <div class="mt-20">
            <component
                :is="component"
                v-model="question.answers"
                :question="question"
                :content="value"
                :contents="contents"
                :current-language="currentLanguage"
                :subtype="question.subtype"
                :question-index="questionIndex"
                :is-completed="isCompleted"
                :show-next-question-select="nextQuestionDependsOnAnswer"
                :questions="questions"
                :is-mandatory.sync="value.question.is_mandatory"
            />
        </div>

        <div v-if="!isLastItem">
            <div class="nextQuestion">
                <t>Next question</t>
            </div>

            <LogicalJump
                v-model="value.next_content_order"
                :contents="contents"
                :question-index="questionIndex"
                :language="currentLanguage.key"
            />
        </div>

        <ImportAnswersModal
            ref="modalImportAnswers"
            @on-add-answers="onAddAnswers"
        />
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';

import LogicalJump from '@/components/pages/resources/surveys/form/question/list/LogicalJump.vue';
import QuestionText from '@/components/pages/resources/surveys/form/question/list/QuestionText.vue';
import QuestionYesNo from '@/components/pages/resources/surveys/form/question/list/QuestionYesNo.vue';
import QuestionSimpleChoice from '@/components/pages/resources/surveys/form/question/list/QuestionSimpleChoice.vue';
import QuestionMultipleChoice from '@/components/pages/resources/surveys/form/question/list/QuestionMultipleChoice.vue';
import QuestionSatisfactionScale from '@/components/pages/resources/surveys/form/question/list/QuestionSatisfactionScale.vue';
import ImportAnswersModal from '@/components/importCsv/ImportAnswersModal.vue';

import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import get from 'lodash.get';
import QuestionFileUpload from './question/list/QuestionFileUpload.vue';
import QuestionRequirement from './question/list/QuestionRequirement.vue';

export default {
    name: 'SurveyListQuestionItem',
    components: {
        ImportAnswersModal,
        LogicalJump,
        HtFormSwitch,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: Object,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        isConversational: {
            type: Boolean,
            required: true,
        },
        isRecurrent: {
            type: Boolean,
            required: true,
        },
        questions: {
            type: Array,
            required: true,
        },
    },

    data() {
        const nextQuestionDependsOnAnswer = this.value.question.answers.some((answer) => answer.next_content_order !== null);

        return {
            nextQuestionDependsOnAnswer,
        };
    },

    computed: {
        isNotConversational() {
            return !this.isConversational;
        },

        isDisabled() {
            return !this.$canUpdate('ModelCompanySurvey') || (this.value.question.type === 'requirement');
        },

        isLastItem() {
            return (this.questionIndex + 1) === this.contents.length;
        },

        question() {
            return this.value.question;
        },

        getPlaceholder() {
            if (this.isDefaultLangSelected || this.question.translations.text[this.shared.session.company.company_language.key] === null) {
                return this.translate('Question title');
            }
            return this.question.translations.text[this.shared.session.company.company_language.key];
        },

        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage.key;
        },

        canBranch() {
            return ['yes_no', 'simple_choice', 'satisfaction_scale'].includes(this.question.type)
                && this.question.subtype !== 'satisfaction_scale_nps';
        },

        component() {
            switch (this.question.type) {
            case 'text':
                return QuestionText;
            case 'yes_no':
                return QuestionYesNo;
            case 'simple_choice':
                return QuestionSimpleChoice;
            case 'multiple_choice':
                return QuestionMultipleChoice;
            case 'satisfaction_scale':
                return QuestionSatisfactionScale;
            case 'file_upload':
                return QuestionFileUpload;
            case 'requirement':
                return QuestionRequirement;
            }
        },
    },

    watch: {
        isLastItem(isLast) {
            if (isLast) {
                this.setNextQuestionDependsOnAnswer(false);
            }
        },
    },

    mounted() {
        this.editQuestion();
    },

    methods: {
        onOpenImportAnswers() {
            this.$refs.modalImportAnswers.open();
        },

        canImportAnswers(type) {
            return this.$canUpdate('ModelCompanySurvey') && (type === 'multiple_choice' || type === 'simple_choice');
        },

        deleteContent() {
            this.$emit('on-delete-content', this.questionIndex);
        },

        editQuestion() {
            const questionTitle = this.$refs[`question-title-${this._uid}`];
            questionTitle.style.height = '1px';
            questionTitle.style.height = `${questionTitle.scrollHeight}px`;
        },

        onAddAnswers(answers) {
            this.question.answers = [];

            this.shared.session.company.company_languages.enabled.forEach((language, languageIndex) => {
                answers.list.forEach((answer, index) => {
                    const entityValueId = get(answer, 'id', null);

                    if (languageIndex === 0) {
                        const newAnswer = {
                            order: index,
                            translations: {
                                text: {},
                            },
                        };
                        this.question.answers.push(newAnswer);
                    }

                    this.question.answers[index].translations.text[language.key] = answer.value;
                    this.question.answers[index].company_entity_value_id = entityValueId;
                });
            });
        },

        setNextQuestionDependsOnAnswer(value) {
            this.nextQuestionDependsOnAnswer = value;

            if (value === false) {
                this.value.question.answers.forEach((answer) => {
                    answer.next_content_order = null;
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyListContentItem.scss"></style>
