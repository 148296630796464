<template>
    <div v-if="isLoaded">
        <div
            id="login-page"
            :class="{ 'with-company': company || !companyNotFound }"
            :style="getBackground"
        >
            <div
                v-if="company || !companyNotFound"
                class="login-background"
                :style="getCompanyCover"
            />
            <div class="login-content-wrapper">
                <div class="login-content">
                    <img
                        v-if="company || !companyNotFound"
                        :src="getCompanyLogoUrl"
                        alt="company logo"
                        class="company-logo"
                    >
                    <h1 v-if="companyNotFound">
                        <t>Company not found</t>
                    </h1>
                    <h1 v-else-if="accountBlocked">
                        <t>Account blocked</t>
                    </h1>
                    <h1 v-else-if="showLogin">
                        <t>Sign in</t>
                    </h1>
                    <div v-else-if="showTwoFactorAuthCode">
                        <h1>
                            <t>Two-factor authentication</t>
                        </h1>

                        <p class="mb-4">
                            <t>This step verifies your identity</t>
                        </p>

                        <p class="mb-4">
                            <t>Please enter the verification code sent by SMS to</t> {{ user.maskedPhoneNumber }}
                        </p>
                    </div>

                    <div v-if="!showSso && !belongsToMultipleCompanies">
                        <div v-if="companyNotFound">
                            <t>Double check the domain of your company</t>
                        </div>
                        <template v-if="accountBlocked">
                            <div>
                                <t>We have blocked your account due to too many incorrect attemps.</t>
                                <br>
                                <t>You can unlock it by reseting your password.</t>
                            </div>
                            <div>
                                <HtButton
                                    type="primary"
                                    size="big"
                                    cypress="reset-password"
                                    :style="getCompanyBranding"
                                    :loading="loading"
                                    :disabled="disableButton"
                                    @click.native="onResetPassword"
                                >
                                    <t>Reset password</t>
                                </HtButton>
                            </div>
                        </template>
                        <form
                            v-if="!companyNotFound && !accountBlocked"
                            @submit.prevent=""
                        >
                            <transition-group name="fade">
                                <HtFormInput
                                    v-show="showLogin
                                        && !showSso
                                        && !showPassword
                                        && !showTwoFactorAuthCode
                                        && !accountBlocked"
                                    id="login-email"
                                    ref="login-email"
                                    key="email"
                                    v-model.trim="user.email"
                                    v-validate.disable="'required|email'"
                                    :data-vv-as="translate('email')"
                                    :disabled="emailDisabled"
                                    :label="translate('Email')"
                                    type="email"
                                    name="email"
                                    cypress="login-email"
                                    show-asterisk
                                    @onKeyupEnter="onValidate"
                                />
                                <div
                                    v-if="showPassword"
                                    key="password"
                                    class="password-wrapper"
                                >
                                    <HtFormInput
                                        id="login-password"
                                        v-model.trim="user.password"
                                        v-validate.disable="'required'"
                                        :data-vv-as="translate('password')"
                                        :label="translate('Password')"
                                        type="password"
                                        name="password"
                                        cypress="login-password"
                                        show-asterisk
                                        @onKeyupEnter="onLogin"
                                    />
                                    <div
                                        v-if="!accountBlocked"
                                        class="forgot-password-link"
                                    >
                                        <span @click="onForgot">
                                            <t>Forgot your password ?</t>
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-if="showTwoFactorAuthCode"
                                    key="two-factor-auth"
                                    class="two-factor-auth-wrapper"
                                >
                                    <HtOtpInput
                                        class="mb-2"
                                        :branding="branding"
                                        @otp-complete="updateOtpCode"
                                    />

                                    <HtButton
                                        class="mt-4 mb-4"
                                        type="primary"
                                        size="big"
                                        cypress="login-continue-two-factor-auth"
                                        :loading="loading"
                                        :style="getCompanyBranding"
                                        @click.native="continueTwoFactorLogin"
                                    >
                                        <t>Continue</t>
                                    </HtButton>
                                    <div class="two-factor-auth-resend">
                                        <t>Didn't receive the code ?</t>
                                        &nbsp;
                                        <a
                                            href="#"
                                            @click.prevent="onResend"
                                        >
                                            <t>Resend</t>
                                        </a>
                                    </div>
                                </div>
                            </transition-group>
                        </form>
                    </div>
                    <div
                        v-if="belongsToMultipleCompanies"
                        class="domain-switcher"
                    >
                        <div
                            v-for="(domain, index) in availableDomains"
                            :key="index"
                            class="domain"
                            @click="redirectToDomain(domain.url)"
                        >
                            <div class="label">
                                <img
                                    :src="$Utils.resolveS3PublicImage(domain.logo)"
                                    alt=""
                                    class="logo"
                                    width="24px"
                                >
                                <span
                                    class="name"
                                    v-text="domain.name"
                                />
                            </div>
                            <FontAwesomeIcon
                                :icon="'arrow-right'"
                                class="arrow-right"
                            />
                        </div>
                    </div>
                    <HtButton
                        v-if="showSso"
                        type="primary"
                        size="big"
                        cypress="login-sso"
                        :style="getCompanyBranding"
                        @click.native="onSso"
                    >
                        <t>Login with</t>&nbsp;{{ company.company_sso.label }}
                    </HtButton>
                    <div v-if="(company || !companyNotFound) && !accountBlocked">
                        <HtButton
                            v-if="!emailDisabled"
                            type="primary"
                            size="big"
                            cypress="login-next"
                            :loading="loading"
                            :style="getCompanyBranding"
                            @click.native="onValidate"
                        >
                            <t>Continue</t>
                        </HtButton>
                        <HtButton
                            v-if="showPassword"
                            type="primary"
                            size="big"
                            cypress="login-connect"
                            :loading="loading"
                            :style="getCompanyBranding"
                            @click.native="onLogin"
                        >
                            <t>Sign in</t>
                        </HtButton>

                        <HtButton
                            v-if="showResendInvitation"
                            type="primary"
                            size="big"
                            cypress="login-resend-invite"
                            :loading="loading"
                            :disabled="disableButton"
                            :style="getCompanyBranding"
                            @click.native="onResendInvitation"
                        >
                            <t>Resend invitation by email</t>
                        </HtButton>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!isCompanyAdeo"
            class="by-heyteam"
        >
            <img src="/static/images/by-heyteam.svg">
        </div>
    </div>
</template>

<script>
import get from 'lodash.get';
import HtButton from '@/components/globals/HtButton.vue';
import HtOtpInput from '@/components/globals/HtOtpInput.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import auth from '@/store/api/auth';

import OAuth from '../OAuth';
import I18n from '../modules/i18n/I18n';

export default {
    name: 'Login',

    components: {
        HtButton,
        FontAwesomeIcon,
        HtOtpInput,
    },

    data() {
        return {
            user: {
                code: '',
                email: '',
                password: '',
                domain: this.$env.getCompanyDomain(document.location.href),
                maskedPhoneNumber: '',
            },
            error: '',
            company: null,
            showSso: false,
            showLogin: true,
            isLoaded: false,
            showPassword: false,
            showTwoFactorAuthCode: false,
            emailDisabled: false,
            companyNotFound: false,
            showForgotPassword: true,
            showResendInvitation: false,
            accountBlocked: false,
            isGenericDomain: false,
            availableDomains: [],
            bypassLoginEmail: false,
            branding: null,
            loading: false,
            disableButton: false,
            otpCode: 0,
            hasPhoneNumber: true,
        };
    },
    computed: {
        isCompanyAdeo() {
            const companyId = get(this.company, 'id');
            return this.$env.get('APP_ENV') === 'production' && (companyId === 335 || companyId === 648 || companyId === 1009);
        },
        belongsToMultipleCompanies() {
            return this.availableDomains.length > 1;
        },
        getBackground() {
            if (!this.company && this.companyNotFound) return 'background: url(/static/images/login/bg-login-error.jpg) no-repeat center center fixed;';
            if (!this.branding) return 'background: url(/static/images/login/bg-login.png) no-repeat center center fixed; background-size: cover;';
            return '';
        },
        getCompanyCover() {
            if (this.company?.login_cover_file == null) return `background: url(/static/images/login/bg-login-side.png) center right no-repeat, ${this.branding ?? 'transparent'}; background-size: cover; padding: 24px; background-origin: content-box;`;
            return `background-image: url(${this.$Utils.resolveS3PublicImage(this.company.login_cover_file.path + this.company.login_cover_file.name, true)});`;
        },
        getCompanyBranding() {
            if (this.branding) return `background-color:${this.branding}; color: white;`;
            return '';
        },
        getCompanyLogoUrl() {
            if (this.company?.login_logo_file?.path) {
                return this.$Utils.resolveS3PublicImage(this.company.login_logo_file.path + this.company.login_logo_file.name, true);
            }
            return '/static/images/logo.svg';
        },
    },
    created() {
        // SNCF : suppression du chatbot quand le user se déconnecte
        if (document.getElementsByClassName('l-clevy-chatbox')) {
            for (const element of document.getElementsByClassName('l-clevy-chatbox')) {
                element.remove();
            }
        }
        if (window.localStorage && window.localStorage.getItem('email')) {
            this.user.email = window.localStorage.getItem('email');
        }
        // Coming from app.heyteam.com
        if (this.$route.query.domain_redirect && this.user.domain !== 'app') {
            this.user.email = this.$route.query.email;
        }
        let language = window.localStorage.getItem('language');
        if (language === null) {
            language = navigator.language.indexOf('fr') > -1 ? 'fr' : 'en';
            window.localStorage.setItem('language', language);
        }
        this.$http.get('getMinimumCompanyInfo')
            .then(({ data }) => {
                this.company = data.company;
                this.$store.dispatch('faviconTitle/setTitle', this.company.name);
                this.$store.dispatch('faviconTitle/setFavicon', data.favicon);
                this.branding = data.company.company_branding ? data.company.company_branding.content.background : null;
                I18n.getTranslationPack(language).then(() => {
                    if (this.$route.query.domain_redirect) {
                        this.onValidate();
                        this.$router.push(this.$route.path);
                        this.bypassLoginEmail = true;
                    } else {
                        this.isLoaded = true;
                    }
                });
            }).catch((data) => {
                if (this.user.domain === 'app') {
                    this.isGenericDomain = true;
                    I18n.getTranslationPack(language).then(() => {
                        this.isLoaded = true;
                    });
                } else {
                    this.isLoaded = true;
                    this.companyNotFound = true;
                    this.$Notifier('App').showError(this.translate(data.message));
                }
            });
    },
    methods: {
        onResend() {
            if (this.loading === true) {
                return;
            }

            this.loading = true;

            auth.resendTwoFactorAuthCode(this.user.email, this.user.domain).then(() => {
                this.$Notifier('App').showInfo(this.translate('A code has been sent to your phone number'));
            }).catch(() => {
                this.$Notifier('App').showError(this.translate('An error occurred when sending code to your phone number'));
            }).finally(() => {
                this.loading = false;
            });
        },
        onResetPassword() {
            if (this.loading === true) {
                return;
            }
            this.loading = true;
            const data = {
                email: this.user.email,
                domain: this.user.domain,
            };
            this.$http.post('auth/forgot_password', data).then(() => {
                this.$Notifier('App').showInfo(this.translate('An email has been sent to your address'));
                this.disableButton = true;
            }).catch(({ data }) => {
                this.$Notifier('App').showError(this.translate(data.status === 'ERROR_APPLICATION'
                    ? data.message
                    : 'It seems you do not belong to this company.'));
                this.saving = false;
            }).finally(() => { this.loading = false; });
        },
        async onValidate() {
            if (this.loading === true) {
                return;
            }
            await this.$validator.validateAll();
            if (this.errors.any()) return;
            this.loading = true;
            if (this.isGenericDomain) {
                this.$http
                    .post('auth/available_domains', this.user)
                    .then(({ data }) => {
                        this.availableDomains = data.data;
                        if (this.availableDomains.length === 1) {
                            this.redirectToDomain(this.availableDomains[0].url);
                        }
                    })
                    .finally(() => { this.loading = false; });
            } else {
                if (this.bypassLoginEmail) {
                    this.isLoaded = true;
                }
                this.$http
                    .post('auth/validate_email', this.user)
                    .then(({ data }) => {
                        this.error = '';
                        this.emailDisabled = true;
                        if (this.company.company_sso?.is_enabled
                            && data.company_user.main_email === 'professional_email'
                            && data.company_user.has_sso === true) {
                            this.showSso = true;
                            this.showForgotPassword = false;
                            this.onSso();
                        } else {
                            this.showPassword = true;
                        }
                    })
                    .catch(({ response }) => {
                        if (response.data.type === 'LOGIN_USER_INVITED') {
                            this.emailDisabled = true;
                            this.showForgotPassword = false;
                            this.showResendInvitation = true;
                        }
                    })
                    .finally(() => { this.loading = false; });
            }
        },
        onResendInvitation() {
            this.loading = true;
            this.$http
                .post('auth/resend_invitation', this.user)
                .then(({ data }) => {
                    this.$Notifier('App').showInfo(this.translate('A new invitation has been sent to {email}', {
                        email: data.email,
                    }));
                    this.disableButton = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateOtpCode(code) {
            this.otpCode = code;
        },
        continueTwoFactorLogin() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) return;

                if (this.loading === true) {
                    return;
                }

                this.loading = true;

                auth.checkTwoFactorAuthCode(this.otpCode, this.user.email, this.user.domain).then(() => {
                    this.loginApp();
                }).catch((error) => {
                    const { data } = error.response;
                    this.$Notifier('App').showError(this.translate(data.message || data));
                    this.$router.push('/Login');
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        async onLogin() {
            await this.$validator.validateAll();
            if (this.errors.any()) {
                return;
            }

            this.loading = true;
            auth.login(this.user).then(async ({ data }) => {
                this.error = '';
                this.hasPhoneNumber = !!data.company_user.phone_number;

                if (this.company.company_security.is_two_factor_auth_enabled
                    && data.company_user.is_two_factor_auth_enabled
                    && this.hasPhoneNumber) {
                    this.processTwoFactorAuth(data);
                    return;
                }

                this.loginApp();
            }).catch((error) => {
                const { data } = error.response;
                this.$Notifier('App').showError(this.translate(data.message || data));

                if (data.password_attempts && data.password_attempts_max
                    && data.password_attempts >= data.password_attempts_max) {
                    this.accountBlocked = true;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        processTwoFactorAuth(data) {
            this.showLogin = false;
            this.showPassword = false;

            this.showTwoFactorAuthCode = true;
            this.user.maskedPhoneNumber = data.masked_phone_number;
        },
        loginApp() {
            // Enregistrement de l'email dans le localStorage
            if (window.localStorage) {
                window.localStorage.setItem('email', this.user.email);
            }

            // Warning si l'utilisateur est sur IE
            if (/MSIE|Trident/.test(window.navigator.userAgent)) {
                this.$Notifier('App').showInfo(this.translate('The full support of Internet Explorer is currently in development.'));
            }
            // Redirection en fonction de l'historique de l'utilisateur
            const redirectIndex = this.$router.historyTrack.length - 1;
            if (redirectIndex >= 0
                && this.$router.historyTrack[redirectIndex].path !== '/'
                && this.$router.historyTrack[redirectIndex].name !== 'ForgotPassword'
                && this.$router.historyTrack[redirectIndex].name !== 'Login'
                && this.$router.historyTrack[redirectIndex].name !== 'InviteMail') {
                this.$router.push({ ...this.$router.historyTrack[redirectIndex] });
            } else {
                this.$router.push('/Dashboard');
            }

            if (this.company.company_security.is_two_factor_auth_enabled && !this.hasPhoneNumber) {
                this.$store.dispatch('common/updateShowTwoFactorAuthInformationModal', true);
            }
        },
        onSso() {
            this.loading = true;
            const config = {
                url: this.company.company_sso.auth_uri,
                client_id: this.company.company_sso.client_id,
                response_type: this.company.company_sso.response_type,
                scope: this.company.company_sso.scope,
                redirect_uri: this.company.company_sso.redirect_uri,
            };
            OAuth.auth(config, {
                200: (data) => {
                    this.user.code = data.code;
                    this.onLogin();
                },
                onError: () => {
                    this.loading = false;
                },
            });
        },
        onForgot() {
            this.$router.push('/ForgotPassword');
        },
        redirectToDomain(url) {
            window.location.replace(`${url}/login?domain_redirect=true&email=${this.user.email}`);
        },
    },
};
</script>
<style lang="scss">
@import '~@/styles/var';

#login-page {
    display: block;
    height: 100vh;
    text-align: center;
    background-color: $background-neutral;

    .login-background {
        height: 100%;
        display: none;
    }

    .login-content-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .company-logo {
            max-height: 80px;
            object-fit: contain;
            margin-bottom: 16px;
        }

        .login-content {
            display: flex;
            flex-direction: column;
            padding: 16px;
            width: 100%;

            h1 {
                font-family: Lato;
                font-size: 4rem;
                font-style: normal;
                font-weight: bold;
                line-height: 5rem;
                letter-spacing: 0rem;
                margin-bottom: 30px;
                color: $neutral-black;
            }

            .two-factor-auth-resend {
                a {
                    color: $semantic-error;
                    text-decoration: none;
                }
            }

            .ht-form {
                position: relative;

                .ht-form-error {
                    position: absolute;
                }
            }

            .password-wrapper {
                position: relative;

                .forgot-password-link {
                    position: absolute;
                    top: -10px;
                    right: 0;
                    transform: translateY(100%);
                    color: $semantic-error;

                    cursor: pointer;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            button {
                width: 100%;
                margin-top: 80px;
            }

            .domain-switcher {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .domain {
                    background-color: $neutral-white;
                    border: 1px solid $neutral-300;
                    border-radius: 8px;
                    padding: 16px 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    .label {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .logo {
                            width: 24px;
                            height: auto;
                        }
                    }

                    .arrow-right {
                        width: 16px;
                        height: 16px;
                        display: none;
                    }

                    &:hover {
                        .arrow-right {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.by-heyteam {
    position: absolute;
    bottom: 24px;
    right: 24px;
}

@media screen and (min-width: $tablet) {
    #login-page.with-company {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        gap: 16px;

        .login-background {
            display: block;
            grid-column: span 4;
        }

        .login-content-wrapper {
            grid-column: span 8;

            .login-content {
                min-width: 500px;
                width: 50%;
                padding: 0;
            }
        }
    }
}
</style>
