<template>
    <form class="form-2">
        <template v-if="subtype === listQuestionSubtype.QUESTION_SUBTYPE_SMILEY">
            <div
                v-for="(answer, index) in getAnswerOrdered"
                :key="index"
            >
                <InputField
                    v-model="answer.translations.text[currentLanguage.key]"
                    :type="'textextra'"
                    :name="`satisfaction-answer-${questionIndex}-${index}`"
                    :placeholder="getPlaceholderAnswers[index]"
                    :validate="isDefaultLangSelected ? 'required' : ''"
                    :validate-as="translate(`answer ${index + 1}`)"
                    :configs="{iconPath: answer.iconPath}"
                    :height="72"
                    :disabled="!$canUpdate('ModelCompanySurvey')"
                    :cypress="`survey-answer-emoji-${index}`"
                />

                <LogicalJump
                    v-if="showNextQuestionSelect"
                    v-model="answer.next_content_order"
                    :contents="contents"
                    :language="currentLanguage.key"
                    :question-index="questionIndex"
                />
            </div>
        </template>
        <template v-else-if="subtype === listQuestionSubtype.QUESTION_SUBTYPE_NPS">
            <div class="question-nps">
                <div
                    v-for="(number, index) in aNumbersNps"
                    :key="`nps-${index}`"
                >
                    {{ number }}
                </div>
            </div>
        </template>
    </form>
</template>

<script>
import { ListQuestionSubtype } from '@/models/common/SurveyQuestionType';
import CompanyLanguage from '@/models/CompanyLanguage';
import LogicalJump from '@/components/pages/resources/surveys/form/question/list/LogicalJump.vue';
import InputField from '@/components/globals/InputField.vue';

export default {
    name: 'QuestionSatisfactionScale',
    components: {
        InputField,
        LogicalJump,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: Array,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        subtype: {
            type: String,
            default: ListQuestionSubtype.QUESTION_SUBTYPE_SMILEY,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        showNextQuestionSelect: {
            type: Boolean,
            required: true,
        },
    },

    shared: {
        session: {},
    },

    data() {
        return {
            aNumbersNps: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            listQuestionSubtype: ListQuestionSubtype,
        };
    },

    computed: {
        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage.key;
        },

        getPlaceholderAnswers() {
            const aPlaceholders = [];

            this.value.forEach((element, index) => {
                if (this.isDefaultLangSelected === false && element.translations.text[this.shared.session.company.company_language.key]) {
                    aPlaceholders.push(element.translations.text[this.shared.session.company.company_language.key]);
                } else {
                    aPlaceholders.push(`${this.translate('Answer title')} ${index + 1}`);
                }
            });

            return aPlaceholders;
        },

        getAnswerOrdered() {
            return [...this.value].sort((a, b) => {
                if (a.order > b.order) {
                    return -1;
                } if (a.order < b.order) {
                    return 1;
                }
                return 0;
            });
        },
    },

    created() {
        if (this.value.length === 0) {
            if (this.subtype === ListQuestionSubtype.QUESTION_SUBTYPE_SMILEY) {
                for (let index = 4; index > 0; index--) {
                    this.value.push({
                        order: index,
                        translations: {
                            text: {},
                        },
                    });
                }
            }
        }

        this.fillIconPath();
    },

    methods: {
        fillIconPath() {
            let path = null;

            switch (this.subtype) {
            case this.listQuestionSubtype.QUESTION_SUBTYPE_SMILEY:
                path = '/static/icons/survey/smiley/';
                break;
            }

            this.value.forEach((element) => {
                if (element.order === 1) {
                    element.iconPath = `${path}SmileyNotGood.svg`;
                } else if (element.order === 2) {
                    element.iconPath = `${path}SmileyMedium.svg`;
                } else if (element.order === 3) {
                    element.iconPath = `${path}SmileyGood.svg`;
                } else if (element.order === 4) {
                    element.iconPath = `${path}SmileyVeryGood.svg`;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped src="./QuestionSatisfactionScale.scss"></style>
