import modalMixin from '@/components/globals/modals/modalMixin';
import CompanyUserProgram from '@/models/CompanyUserProgram';

export default {
    mixins: [modalMixin],
    methods: {
        goToDraftUserModal(userProgramDraft) {
            const programName = userProgramDraft.company_program?.name_translated;
            let resolveDraft;
            let rejectDraft;
            const promise = new Promise((resolve, reject) => {
                resolveDraft = resolve;
                rejectDraft = reject;
            });
            this.openDialogModal(
                'goToDraftUserModal',
                this.translate('This user already has an existing draft'),
                this.translate('This user has a current draft in the program {programName}. You can continue with the existing draft or create a new one.', { programName }),
                [
                    {
                        title: this.translate('Create New'),
                        handler: async () => {
                            try {
                                const draftToDelete = new CompanyUserProgram(['id']);
                                draftToDelete.id = userProgramDraft.id;

                                await draftToDelete.delete();
                                resolveDraft('create');
                            } catch (data) {
                                this.$Notifier('App').showError(this.translate(data.message));
                                rejectDraft();
                            }
                        },
                    },
                    {
                        title: this.translate('Use existing draft'),
                        handler: () => {
                            resolveDraft('existing');
                        },
                    }],
                {
                    'before-close': () => {
                        resolveDraft('close');
                    },
                },
            );
            return promise.finally(() => {
                this.$modal.hide('goToDraftUserModal');
            });
        },
    },
};
