<template>
    <ResourceListing
        :title="title"
        :items="formattedSurveys"
        :columns="columns"
        permission="ModelCompanySurvey"
        permission-type-on-row-clicked="read"
        empty-message="There are no surveys to display yet..."
        has-quick-actions
        :is-modalable="false"
        @on-create="openForm"
        @on-row-clicked="onRowClicked"
        @on-select-filter="fetchData"
        @on-action-clicked="onActionClicked"
    >
        <template #cell(number_of_responses)="scope">
            <span v-if="scope.item.number_of_target_users > 0">
                {{ scope.item.number_of_responses }} / {{ scope.item.number_of_target_users }}
            </span>
            <span v-if="scope.item.number_of_target_users === 0 || !scope.item.number_of_target_users">
                -
            </span>
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import I18n from '@/modules/i18n/I18n';

export default {
    permissions: [
        'ModelCompanySurvey',
    ],

    components: {
        ResourceListing,
    },

    data() {
        return {
            surveys: [],
            columns: [
                {
                    label: this.translate('Status'),
                    key: 'status',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Answers'),
                    key: 'number_of_responses',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    computed: {
        title() {
            if (this.surveys.length === 0) {
                return this.translate('No survey');
            }

            return this.translate('{count} survey | {count} surveys', {
                count: this.surveys.length,
            });
        },

        formattedSurveys() {
            const colors = {
                draft: 'orange',
                scheduled: 'purple',
                in_progress: 'green',
                completed: 'grey',
            };

            const statuses = {
                draft: I18n.translate('Draft'),
                scheduled: I18n.translate('Scheduled'),
                in_progress: I18n.translate('In progress'),
                completed: I18n.translate('Closed'),
            };

            return this.surveys.map((survey) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanySurvey', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...survey,
                    status_color: colors[survey.status],
                    status_label: statuses[survey.status],
                    quick_actions: quickActions,
                };
            });
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        replicateSurvey(survey) {
            api.configuration.surveys.replicate(survey.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: survey.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: survey.resource.name,
                    }));
                });
        },
        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateSurvey(item);
                break;
            }
        },
        openForm() {
            return this.$router.push({
                name: 'ResourcesSurveysForm',
            });
        },

        onRowClicked(row) {
            return this.$router.push({
                name: 'ResourcesSurveysForm',
                params: {
                    id: row.item.id,
                },
            });
        },

        fetchData() {
            this.$store.dispatch('resources/setLoading', true);

            api.configuration
                .surveys
                .getAll({
                    filters: this.$store.state.resources.filters,
                })
                .then((res) => {
                    this.surveys = res.data.data;

                    this.$store.dispatch('resources/setLoading', false);
                });
        },
    },
};
</script>
