<template>
    <component
        :is="getComponent"
        :choices="questionResults.choices"
        :answers="questionResults.answers"
        :no-user-survey-selected="noUserSurveySelected"
    />
</template>

<script>
import Button from '@/components/globals/Button.vue';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import ResultsYesNo from './YesNo/ResultsYesNo.vue';
import ResultsSimpleChoice from './SimpleChoice/ResultsSimpleChoice.vue';
import ResultsMultipleChoice from './MultipleChoice/ResultsMultipleChoice.vue';
import ResultsSatisfactionScaleNps from './SatisfactionScaleNps/ResultsSatisfactionScaleNps.vue';
import ResultsSatisfactionScaleSmiley from './SatisfactionScaleSmiley/ResultsSatisfactionScaleSmiley.vue';

export default {
    name: 'ChoiceAnswerResults',
    components: { Button },

    props: {
        questionResults: {
            type: Object,
            required: true,
        },
        noUserSurveySelected: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        getComponent() {
            switch (this.questionResults.question.type) {
            case CompanySurveyQuestion.TYPE_YES_NO:
                return ResultsYesNo;
            case CompanySurveyQuestion.TYPE_SIMPLE_CHOICE:
                return ResultsSimpleChoice;
            case CompanySurveyQuestion.TYPE_MULTIPLE_CHOICE:
                return ResultsMultipleChoice;
            case CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_NPS:
                return ResultsSatisfactionScaleNps;
            case CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_SMILEY:
                return ResultsSatisfactionScaleSmiley;
            }
        },
    },
};
</script>
