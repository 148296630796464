import Vue from 'vue';
import get from 'lodash.get';
import Notifier from '@/Notifier';
import HelloSign from 'hellosign-embedded';
import Env from '@/Env';

export default class HellosignHelper {
    constructor(clientId, requirement) {
        this.client = new HelloSign({
            clientId,
            skipDomainVerification: Env.get('APP_ENV') !== 'production',
            debug: Env.get('APP_ENV') !== 'production',
        });

        this.requirement = requirement;
    }

    /**
     * @returns {string} url de l'instance d'HelloSign à ouvrir
     */
    async loadUrl() {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post(`miscellaneous/hellosign/url/${this.requirement.id}`).then((response) => {
                resolve(response.data.signature_url);
            }).catch(({ response }) => {
                const message = get(response, 'data.message', null);

                if (message) Notifier.getInstance('App').showError(message);

                reject();
            });
        });
    }

    async updateSignature() {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post(`miscellaneous/hellosign/update/${this.requirement.id}`).then((requirement) => {
                resolve(requirement);
            }).catch(({ response }) => {
                const message = get(response, 'data.message', null);

                if (message) Notifier.getInstance('App').showError(message);

                reject();
            });
        });
    }

    sign() {
        return new Promise((resolve, reject) => {
            this.loadUrl().then((url) => {
                this.client.open(url, {
                    testMode: Env.get('APP_ENV') !== 'production',
                });

                this.client.on('sign', () => {
                    this.updateSignature().then((requirement) => {
                        resolve(requirement);
                    }).catch(() => {
                        reject();
                    });
                });

                this.client.on('error', () => {
                    this.client.close();
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }
}
