<template>
    <div class="ht-participant">
        <div
            v-if="label"
            class="ht-participant-label"
        >
            {{ label }}
        </div>
        <div
            v-if="isEmpty(item)"
            class="ht-participant-empty d-flex align-items-center branding-color branding-color-darken-hover"
            :data-cy="'input-select-user-'+cypress"
            @click="$emit('on-change', item)"
        >
            <div class="d-flex align-items-center justify-content-center icon-plus mr-3 branding-bg branding-bg-darken-hover">
                <FontAwesomeIcon :icon="['fal', 'plus']" />
            </div>
            <div class="ht-participant-empty-label">
                {{ buttonLabel }}
            </div>
        </div>
        <div v-else>
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex align-items-center">
                    <slot
                        name="item-icon"
                        :value="item"
                    />
                    <div class="ht-participant-full-label">
                        <slot
                            name="item-label"
                            :value="item"
                        >
                            {{ item }}
                        </slot>
                    </div>
                </div>
                <div
                    v-if="canDelete"
                    class="ht-participant-full-icon d-flex align-items-center justify-content-center"
                    :data-cy="'input-remove-user-'+cypress"
                    @click="$emit('on-delete', item)"
                >
                    <FontAwesomeIcon
                        class="icon"
                        :icon="['fal', 'times']"
                    />
                </div>
            </div>
            <Button
                class="inner-box branding-color branding-border-color branding-border-color-darken-hover"
                :data-cy="'input-change-user-'+cypress"
                @click="$emit('on-change', item)"
            >
                <t>Edit</t>
            </Button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HtItemSelectCard',

    props: {
        label: {
            type: String,
            default: () => '',
        },
        buttonLabel: {
            type: String,
            required: true,
        },
        item: {
            type: [Object, String],
            default: () => {},
        },
        isEmpty: {
            type: Function,
            required: true,
        },
        canDelete: {
            type: Boolean,
            default: true,
        },
        cypress: {
            type: String,
            default: '',
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.ht-participant {
    &:not(:last-child) {
        margin-bottom: 32px;
    }

    &-label {
        font-size: 16px;
        font-weight: 700;
        color: $color-text;
        margin-bottom: 8px;
    }

    &-empty {
        cursor: pointer;

        .icon-plus {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            color: white;
        }

        &-label {
            font-weight: 700;
            font-size: 16px;
        }
    }

    &-full {
        &-label {
            font-size: 14px;
            font-weight: 400;
            color: $black-2;
        }
        &-icon {
            cursor: pointer;
            font-size: 12px;
            width: 40px;
            height: 40px;
        }
    }
}

.user-image {
    width: 40px;
    height: 40px;
}
</style>
