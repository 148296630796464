import surveys from '@/store/api/user/surveys';
import requirementCategories from '@/store/api/user/requirementCategories';
import requirements from '@/store/api/user/requirements';
import requirementGroups from './requirementGroups';
import planningEvents from './planningEvents';
import planningEventNotes from './planningEventNotes';
import enrolling from './enrolling';

export default {
    surveys,
    requirementCategories,
    requirementGroups,
    requirements,
    planningEvents,
    planningEventNotes,
    enrolling,
};
