<template>
    <div class="question">
        <span class="question-input-title">
            {{ questionResults.question.label }}
        </span>
        <div
            v-if="noUserSurveySelected"
            class="mt-20"
        >
            <t :count="questionResults.number_of_question_done_unskipped">
                {count} answer | {count} answers
            </t>
            /
            <t :count="questionResults.number_of_question_done_totally">
                {count} respondent | {count} respondents
            </t>
        </div>
        <component
            :is="component"
            class="mt-20"
            :question-results="questionResults"
            :no-user-survey-selected="noUserSurveySelected"
        />
        <div
            v-if="isUserSurveySelected && questionResults.comment"
            class="question-comment"
        >
            {{ questionResults.comment }}
        </div>
    </div>
</template>

<script>
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import FreeAnswerResults from './result/FreeAnswerResults.vue';
import ChoiceAnswerResults from './result/ChoiceAnswerResults.vue';

export default {
    name: 'SurveyListResultItem',
    inject: ['$validator'],

    props: {
        questionResults: {
            type: Object,
            required: true,
        },
        noUserSurveySelected: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        component() {
            return CompanySurveyQuestion.FREE_ANSWERS.includes(this.questionResults.question.type)
                ? FreeAnswerResults
                : ChoiceAnswerResults;
        },
        isUserSurveySelected() {
            return !this.noUserSurveySelected;
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyListContentItem.scss"></style>
