<template>
    <StepperTabLayout v-if="htPermissionGroupCollection.isLoaded()">
        <template #main>
            <div
                v-for="htPermissionGroup in htPermissionGroupList"
                :key="htPermissionGroup.id"
            >
                <Dropdown
                    :title="htPermissionGroup.name"
                    :description="htPermissionGroup.description"
                >
                    <PermissionsListItem
                        v-for="companyRolePermission in permissionsByGroupId(htPermissionGroup.id)"
                        :key="companyRolePermission.id"
                        class="permission-item"
                        :company-role-permission="companyRolePermission"
                        :is-editable="item.is_editable"
                    />
                </Dropdown>
            </div>
        </template>
        <template #buttonsFooter>
            <Button
                class="negative mr-4"
                @click="$emit('goTo', 'settings')"
            >
                <t>Back</t>
            </Button>
            <Button
                :state="buttonState"
                @click="onSave"
            >
                <t v-if="isEditable">
                    Save permissions
                </t>
                <t v-else>
                    Next
                </t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import CompanyRole from '@/models/CompanyRole';
import Dropdown from '@/components/globals/containers/Dropdown.vue';
import orderBy from 'lodash.orderby';
import HtPermissionGroupCollection from '@/models/HtPermissionGroupCollection';
import PermissionsListItem from './lists/items/PermissionsListItem.vue';

export default {
    name: 'RolesAndPermissionsFormPermissions',
    components: {
        Dropdown,
        PermissionsListItem,
    },
    props: {
        item: {
            type: CompanyRole,
            required: true,
        },
    },

    data() {
        return {
            htPermissionGroupCollection: new HtPermissionGroupCollection([
                'id',
                'name',
                'description',
                'order',
            ]),
            showPageDashboardSpecificReportsPermission: false,
        };
    },

    computed: {
        htPermissionGroupList() {
            return orderBy(this.htPermissionGroupCollection.models, ['order'], ['asc']);
        },

        buttonState() {
            return this.item._state.isSaving ? 'loading' : 'idle';
        },

        isEditable() {
            return this.item.is_editable;
        },
    },

    async created() {
        this.htPermissionGroupCollection.get();
        const response = await this.$http.get('dashboard/report/metabase-dashboards');
        this.showPageDashboardSpecificReportsPermission = response.data.dashboards.length > 0;
    },

    methods: {
        async onSave() {
            if (!this.isEditable) {
                this.$emit('goTo', 'usersAndPerimeters');
                return;
            }

            if (await this.$validator.validateAll()) {
                await this.item.save();
                this.$emit('goTo', 'usersAndPerimeters');
            }
        },

        displayException(perm) {
            if (perm.component === 'page_dashboard_specific_reports') {
                return this.showPageDashboardSpecificReportsPermission;
            }

            return true;
        },

        permissionsByGroupId(htPermissionGroupId) {
            const permissions = this.item.company_role_permission.models
                .filter((perm) => {
                    const isDisplay = perm.is_display
                        && perm.ht_permission.ht_permission_group_id === htPermissionGroupId
                        && this.displayException(perm);

                    return isDisplay;
                });

            return orderBy(permissions, ['ht_permission.order'], ['asc']);
        },
    },
};

</script>

<style lang="scss" scoped src="./RolesAndPermissionsFormPermissions.scss"></style>
