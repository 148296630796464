<template>
    <div>
        <IconLoading v-if="isFetching" />
        <SurveyFormResultsEmptyState
            v-else-if="noResults"
            class="empty-state"
        />
        <template v-else>
            <SurveyFormResultsTopBar
                :is-exporting="isExporting"
                :selected-filters="selectedFilters"
                :show-export="userSurveys.length > 0"
                @on-change-filter="onChangeFilter"
                @on-click-export="onExport"
            />
            <div class="row">
                <div class="col-md-4">
                    <HtSearchField
                        id="participants-search"
                        v-model.trim="userSurveySearch"
                        name="participants-search"
                        class="mb-3"
                    />

                    <SurveyFormResultsParticipants
                        :user-surveys="userSurveysFiltered"
                        :is-targeting-program="item.resource.programs[0].program.type.slug !== 'default'"
                        :current-user-survey-id="currentUserSurveyId"
                        @onSelectUserSurvey="onSelectUserSurvey"
                        @on-click-all-results="onClickAllResults"
                    />
                </div>
                <SurveyListResult
                    class="survey-list-result col-md-6 offset-md-1"
                    :questions-results="results"
                    :occurrences="occurrences"
                    :current-user-survey-id="currentUserSurveyId"
                    :no-user-survey-selected="noUserSurveySelected"
                    @onSelectUserSurvey="onSelectUserSurvey"
                />
            </div>
        </template>
    </div>
</template>

<script>
import api from '@/store/api';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import SurveyListResult from './SurveyListResult.vue';
import SurveyFormResultsTopBar from './SurveyFormResultsTopBar.vue';
import SurveyFormResultsParticipants from './result/Participants/SurveyFormResultsParticipants.vue';
import SurveyFormResultsEmptyState from './SurveyFormResultsEmptyState.vue';

export default {
    name: 'SurveyFormResults',
    components: {
        IconLoading,
        SurveyListResult,
        SurveyFormResultsTopBar,
        SurveyFormResultsParticipants,
        SurveyFormResultsEmptyState,
        HtSearchField,
    },
    data() {
        return {
            results: [],
            userSurveys: [],
            selectedFilters: [],
            occurrences: [],
            currentUserSurveyId: null,
            /**
             * Does this survey contains results
             */
            noResults: false,
            isFetching: false,
            isExporting: false,
            userSurveySearch: null,
        };
    },

    computed: {
        userSurveysFiltered() {
            if (!this.userSurveySearch) {
                return this.userSurveys;
            }

            return this.userSurveys.filter((userSurvey) => (
                this.$Utils.isSubstringWithoutDiacritics(this.userSurveySearch, `${userSurvey.item.enrollee.firstname} ${userSurvey.item.enrollee.lastname}`)
                || this.$Utils.isSubstringWithoutDiacritics(this.userSurveySearch, `${userSurvey.item.participant.firstname} ${userSurvey.item.participant.lastname}`)
            ));
        },
        item() {
            return this.$store.state.stepperform.item;
        },

        /**
         * @returns {[{company_entity_id: number, company_entity_value_id: number}]}
         */
        mapSelectedFilters() {
            return this
                .selectedFilters
                .map(({ company_entity_id, company_entity_value_id }) => ({ company_entity_id, company_entity_value_id }));
        },

        noUserSurveySelected() {
            return this.currentUserSurveyId === null;
        },
    },

    async created() {
        await this.fetchAndSetData();
        this.noResults = this.userSurveys.length === 0;
    },

    methods: {
        /**
         * @returns {Promise<Array>}
         */
        async fetchData() {
            return this.$http.post(
                `results/surveys/${this.item.id}`,
                { entities: this.mapSelectedFilters },
            );
        },

        async fetchAndSetData() {
            this.isFetching = true;
            try {
                const response = await this.fetchData();
                const { questions_results, user_surveys } = response.data;
                this.results = questions_results;
                this.userSurveys = user_surveys;
            } finally {
                this.isFetching = false;
            }
        },

        async onClickAllResults() {
            if (this.userSurveys.length === 0) {
                return;
            }
            this.currentUserSurveyId = null;
            await this.fetchAndSetData();
        },

        async fetchSelectedUserSurveyResults() {
            return this.$http.get(`results/user-surveys/${this.currentUserSurveyId}`);
        },

        onChangeFilter(filters) {
            this.selectedFilters = filters;
            this.currentUserSurveyId = null;
            this.fetchAndSetData();
        },

        async onSelectUserSurvey(id, occurrences = []) {
            if (id === this.currentUserSurveyId) {
                return;
            }
            this.isFetching = true;
            this.currentUserSurveyId = id;
            const results = await this.fetchSelectedUserSurveyResults();
            this.results = results.data.questions_results;
            this.occurrences = occurrences;
            this.isFetching = false;
        },

        async onExport(format) {
            this.isExporting = true;
            api.miscellaneous.exportSurveys({
                survey_ids: [this.item.id],
                filters: this.mapSelectedFilters,
                format,
            }).then((data) => {
                window.location = data.data.link;
            }).finally(() => this.isExporting = false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

@media (max-width: $tablet) {
    .survey-list-result {
        margin-top: 48px;
    }
}
</style>
