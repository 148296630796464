<template>
    <div class="item-container">
        <div
            v-if="isSplitted"
            class="form-card pt-2 pb-4 mb-3"
        >
            <div class="d-flex row align-items-center p-4">
                <div class="col-8">
                    <strong><t>{{ companyRolePermission.ht_permission.name }}</t></strong>
                    <div
                        v-if="companyRolePermission.ht_permission.description"
                        class="description mt-2"
                    >
                        <t>{{ companyRolePermission.ht_permission.description }}</t>
                    </div>
                </div>
                <div class="col-4">
                    <HtFormSwitch
                        :id="`${companyRolePermission.ht_permission.name}-enabled-all`"
                        v-model="toggle"
                        :name="`${companyRolePermission.ht_permission.name}-enabled-all`"
                        :on-label="translate('Enable all')"
                        :off-label="translate('Enable all')"
                        :disabled="!isEditable"
                        :show-optional="false"
                        :is-branded="true"
                        class="mb-0"
                        @input="toggleAllAccess"
                    />
                </div>
            </div>
            <CrudCheckable
                v-model="companyRolePermission"
                :disabled="!isEditable"
            />
        </div>
        <div
            v-else
            class="d-flex row align-items-center py-2"
        >
            <div class="col-7">
                <div class="mb-2">
                    <strong><t>{{ companyRolePermission.ht_permission.name }}</t></strong>
                </div>
                <div class="description">
                    <t>{{ companyRolePermission.ht_permission.description }}</t>
                </div>
            </div>
            <div class="col-5">
                <HtFormSwitch
                    :id="companyRolePermission.ht_permission.name"
                    v-model="companyRolePermission.can_read"
                    :name="companyRolePermission.ht_permission.name"
                    :on-label="translate('Can access')"
                    :off-label="translate('Cannot access')"
                    :is-branded="true"
                    :disabled="!isEditable"
                    :show-optional="false"
                    @input="toggleAllAccess(companyRolePermission.can_read)"
                />
            </div>
        </div>
    </div>
</template>
<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyRolePermission from '@/models/CompanyRolePermission';
import HtPermission from '@/models/HtPermission';

export default {
    name: 'PermissionsListItem',
    components: {
        HtFormSwitch,
    },

    props: {
        companyRolePermission: {
            type: CompanyRolePermission,
        },
        isEditable: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            toggle: false,
        };
    },

    computed: {
        isSplitted() {
            return this.companyRolePermission.ht_permission.type === HtPermission.TYPE_SPLITTED;
        },
        mappingFields() {
            return [
                {
                    can: 'can_create',
                    enabled: 'create_enabled',
                },
                {
                    can: 'can_read',
                    enabled: 'read_enabled',
                },
                {
                    can: 'can_update',
                    enabled: 'update_enabled',
                },
                {
                    can: 'can_delete',
                    enabled: 'delete_enabled',
                },
                {
                    can: 'can_validate',
                    enabled: 'validate_enabled',
                },
            ];
        },
    },

    watch: {
        'companyRolePermission.can_create': function () {
            this.updateToggle();
        },
        'companyRolePermission.can_read': function () {
            this.updateToggle();
        },
        'companyRolePermission.can_update': function () {
            this.updateToggle();
        },
        'companyRolePermission.can_delete': function () {
            this.updateToggle();
        },
        'companyRolePermission.can_validate': function () {
            this.updateToggle();
        },
    },

    created() {
        this.updateToggle();
    },

    methods: {
        updateToggle() {
            let toggle = false;

            for (const field of this.mappingFields) {
                if (this.companyRolePermission.ht_permission[field.enabled]) {
                    toggle = Boolean(this.companyRolePermission[field.can]);

                    if (!toggle) {
                        break;
                    }
                }
            }

            this.toggle = toggle;
        },
        toggleAllAccess(value) {
            this.mappingFields.forEach((field) => {
                if (this.companyRolePermission.ht_permission[field.enabled]) {
                    this.companyRolePermission[field.can] = value;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./PermissionsListItem.scss"></style>
