<template>
    <HtEntitiesFiltersWrapper
        :value="selectedFilters"
        class="survey-results-top-bar"
        @input="($event) =>$emit('on-change-filter', $event)"
    >
        <template #button>
            <div class="export-btns">
                <HtButton
                    v-if="showExport"
                    type="secondary"
                    :loading="isExporting"
                    @click.native="$emit('on-click-export', 'csv')"
                >
                    <t>CSV Export</t>
                </HtButton>
                <HtButton
                    v-if="showExport"
                    type="secondary"
                    :loading="isExporting"
                    @click.native="$emit('on-click-export', 'xlsx')"
                >
                    <t>Excel Export</t>
                </HtButton>
            </div>
        </template>
    </HtEntitiesFiltersWrapper>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';

export default {
    name: 'SurveyFormResultsTopBar',
    components: {
        HtEntitiesFiltersWrapper,
        HtButton,
    },
    props: {
        isExporting: {
            type: Boolean,
            required: true,
        },
        selectedFilters: {
            type: Array,
            required: true,
        },
        showExport: {
            type: Boolean,
            required: true,
        },
    },

};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.survey-results-top-bar{
    margin-bottom: 16px;

    .export-btns{
        display: flex;
        gap: 8px;
    }
}

@media (min-width: $tablet) {
    ::v-deep .ht-button {
        width: 140px;
    }
}
</style>
