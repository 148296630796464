<template>
    <transition name="fade">
        <div v-if="companyUserProgram.isLoaded()">
            <form
                class="form-2 spaced"
                @submit.prevent=""
            >
                <div class="modal-content-item-wrapper">
                    <p class="text-align-justify">
                        <span class="key-date-warnings">
                            {{ keyDateWarning }}
                        </span>
                    </p>
                    <div class="row">
                        <HtFormInput
                            :id="`old_${keyDateName}`"
                            :value="$Utils.moment(keyDate.starts_at).format('L')"
                            :name="`old_${keyDateName}`"
                            :label="`${translate('Old')} ${keyDateName}`"
                            :disabled="true"
                            class="col-md-5"
                            :show-optional="false"
                        />
                        <div class="col-md-2 d-flex justify-self-center">
                            <img
                                width="40"
                                src="/static/icons/arrow.svg"
                            >
                        </div>
                        <div class="col-md-5">
                            <HtFormFlatPickr
                                :id="`new_${keyDateName}`"
                                v-model="newKeyDate"
                                v-validate.disable="'required'"
                                :name="`new_${keyDateName}`"
                                :label="`${translate('New')} ${keyDateName}`"
                                :data-vv-as="`${translate('New')} ${keyDateName}`"
                                :is-working-day="false"
                            />
                        </div>
                    </div>
                    <template v-if="isMainKeyDate">
                        <HtFormInput
                            :id="'subject'"
                            v-model="companyUserProgram.mail_subject"
                            :name="'subject'"
                            :placeholder="translate('Subject')"
                            :label="translate('Subject')"
                        />
                        <HtFormEditor
                            :id="'mail_content'"
                            v-model="companyUserProgram.mail_content"
                            :name="'mail_content'"
                            :label="translate('Message sent to {firstname}', { firstname: companyUserProgram.company_user.firstname})"
                        />
                        <fieldset class="big">
                            <HtFormSwitch
                                :id="'toggle-send-sms'"
                                v-model="isSendableSms"
                                :name="'toggle-send-sms'"
                                :label="`${translate('Send a text message to notify')} ${companyUserProgram.company_user.firstname}`"
                                :disabled="(companyUserProgram.company_user.phone_number) ? false : true"
                                :show-optional="false"
                            />
                            <div
                                v-if="isSendableSms"
                                class="item-content"
                            >
                                <HtFormTextarea
                                    :id="'sms_textarea'"
                                    v-model.trim="companyUserProgram.sms_content"
                                    v-validate.disable="'required'"
                                    :name="'sms_textarea'"
                                    :data-vv-as="translate('sms')"
                                />
                            </div>
                        </fieldset>

                        <HtFormFlatPickr
                            :id="'mail_schedule'"
                            v-model="companyUserProgram.mail_schedule"
                            v-validate.disable="'required'"
                            :label="translate('Schedule this e-mail')"
                            :name="'mail_schedule'"
                            :inline="true"
                            :min-date="minDate"
                            :max-date="maxDate"
                            :data-vv-as="translate('mail schedule')"
                            :is-working-day="false"
                        />

                        <div class="mailing-info">
                            <span><t>It will be sent automatically at 9:00 a.m. on the selected date above</t></span><br>
                            <span><t>If you select today’s date, the e-mail will be sent now</t></span>
                        </div>
                    </template>

                    <fieldset :class="{'error': errors.has('confirm_arrival_date')}">
                        <label class="label-checkbox">
                            <input
                                v-validate.disable="'required'"
                                type="checkbox"
                                name="confirm_arrival_date"
                                :data-vv-as="translate('arrival date\'s confirmation')"
                            >
                            <div><t>{{ labelCheckBox }}</t></div>
                        </label>
                    </fieldset>
                </div>
            </form>
            <div class="modal-actions right">
                <Button
                    class="negative"
                    @click="onClose()"
                >
                    <t>Cancel</t>
                </Button>
                <Button
                    :state="buttonState"
                    @click="onValidate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </div>
    </transition>
</template>

<script>
import CompanyUserProgram from '@/models/CompanyUserProgram';
import profileEventBus from '@/eventBus/profileEventBus';
import CompanyUserProgramKeyDate from '@/models/CompanyUserProgramKeyDate';
import HtFormSwitch from './globals/HtFormSwitch.vue';

export default {
    name: 'KeyDateEdit',
    components: { HtFormSwitch },
    inject: ['modal'],

    props: {
        companyUserProgramId: {
            type: Number,
            required: true,
        },
        keyDate: {
            type: CompanyUserProgramKeyDate,
            required: true,
        },
    },

    data() {
        return {
            newKeyDate: null,
            buttonState: 'idle',
            isSendableSms: false,
            error: '',
            companyUserProgram: new CompanyUserProgram([
                'id',
                'company_user_id',
                'mail_subject',
                'mail_content',
                'mail_schedule',
                'sms_content',
                'type',
            ]).where([
                ['id', '=', this.companyUserProgramId],
            ]).with({
                keyDates: (query) => {
                    query
                        .select(['id', 'starts_at'])
                        .with({
                            keyDate: (query) => {
                                query.select(['id', 'is_main']);
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'phone_number']);
                },
            }),
        };
    },

    computed: {
        labelCheckBox() {
            return this.translate('I confirm the change of {enrollee_name}\'s {key_date}', {
                enrollee_name: this.companyUserProgram.company_user.firstname,
                key_date: this.keyDateName,
            });
        },

        minDate() {
            return 'today';
        },

        mainKeyDate() {
            return this.companyUserProgram.key_dates.models.find((date) => date.key_date.is_main);
        },

        maxDate() {
            return this.$Utils.moment(this.mainKeyDate.starts_at) > this.$Utils.moment()
                ? this.mainKeyDate.starts_at
                : 'today';
        },

        keyDateWarning() {
            return this.translate(
                'Changing the date of {key_date} will update the entire onboarding program, including events, emails and other resources. Actions that were already completed will remain completed. Please keep in mind that this change is definitive.',
                { key_date: this.keyDateName },
            );
        },

        keyDateName() {
            return this.keyDate.key_date.name_translated;
        },

        isMainKeyDate() {
            return !!this.keyDate.key_date.is_main;
        },
    },

    created() {
        this.companyUserProgram.get().then(() => {
            if (this.isMainKeyDate) {
                if (this.companyUserProgram.company_user.phone_number) this.isSendableSms = true;

                if (this.companyUserProgram.sms_content === null) {
                    this.loadSmsContent();
                }
            }

            this.modal.setTitle(this.translate('Edit {enrollee_name}\'s {key_date}', {
                enrollee_name: this.companyUserProgram.company_user.firstname,
                key_date: this.keyDateName,
            }));
        });
    },

    methods: {
        onClose() {
            this.modal.close();
        },

        loadSmsContent() {
            return new Promise((resolve) => {
                this.API.get(`email_template/${this.companyUserProgram.id}`, {
                    200: ({ sms_content }) => {
                        this.companyUserProgram.sms_content = sms_content.replace(/<[^>]*>/g, '');
                        resolve();
                    },
                });
            });
        },

        onValidate() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    if (!this.newKeyDate || this.errors.any() || this.hasEmailErrors) {
                        this.$Utils.firstParentScrollableToTop(this.$el);
                        return;
                    }

                    if (this.isMainKeyDate && !this.isSendableSms) {
                        this.companyUserProgram.sms_content = null;
                    }

                    this.buttonState = 'loading';

                    const modelKeyDate = this.companyUserProgram.key_dates.models.find((item) => item.id === this.keyDate.id);
                    if (modelKeyDate) {
                        modelKeyDate.starts_at = this.newKeyDate;
                    }

                    this.companyUserProgram.save().then(() => {
                        profileEventBus.$emit('reload-user');
                        this.modal.close();
                    }).finally(() => {
                        this.buttonState = 'idle';
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
	@import '~@/styles/var';

    .key-date-warnings {
        color: red;
    }

	.label-checkbox {
		display:flex !important;
		justify-content: flex-start;
		margin-bottom: 20px !important;

		input {
			flex: 0.2
		}
	}

	fieldset {
		margin: 0 !important;
	}

	.big {
		margin-bottom: 20px !important;
	}

	.modal-content-item-wrapper {
		justify-content: flex-start
	}

	.inline-date {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.arrow-center {
		align-self: center;
	}

	.text-align-justify {
		text-align: justify;
		margin-bottom: 20px;
	}

	.margin-bottom {
		font-weight: bold;
		margin-bottom: 20px;
	}
</style>
