import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} ProgramTaskSimulated
 * @property {number} resource_program_id
 * @property {Object} resourceProgram
 * @property {string} name
 * @property {Array} filters
 * @property {string} resource
 * @property {Array} dependencies
 * @property {number|null} dependent
 * @property {number} resource_id
 * @property {Participant[]} participants
 * @property {{color: string, label: string, order: number}} period
 * @property {boolean} is_locked
 * @property {string} availability_date
 * @property {string} completion_date
 * @property {number} order_date
 */

/**
 * @typedef {{data: {tasks: ProgramTaskSimulated[], key_dates: KeyDate[]}}} SimulateResponse
 */

export default {
    /**
     * @param {number} id
     * @param {any} data
     * @returns {Promise<SimulateResponse>}
     */
    simulate(id, data) {
        const endpoint = api.utils.replaceVariables(api.endpoints.CONFIGURATION_PROGRAMS_SIMULATE, {
            program: id,
        });

        return Vue.prototype.$http.post(endpoint, data);
    },

    getPrograms(params) {
        return Vue.prototype.$http.get(api.endpoints.CONFIGURATION_PROGRAMS, {
            params,
        });
    },

    getSelfEnrollablePrograms() {
        return Vue.prototype.$http.get('/programs/self-enrollables');
    },

    getProgram(id) {
        const params = { program: id };

        const endpoint = api.utils
            .replaceVariables(api.endpoints.CONFIGURATION_PROGRAM_SHOW, params);

        return Vue.prototype.$http.get(endpoint);
    },

    /**
     *
     * @param {int} companyProgramId
     */
    selfEnrollOnProgram(companyProgramId) {
        return Vue.prototype.$http.post('/programs/self-enroll', { companyProgramId });
    },
};
