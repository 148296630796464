import Model from '@tony.caron/node-model-proxy/Model';
import HtPermission from './HtPermission';
import CompanyRolePermissionCollection from './CompanyRolePermissionCollection';

export default class CompanyRolePermission extends Model {
    modelConfig() {
        return {
            collection: CompanyRolePermissionCollection,
        };
    }

    modelRelations() {
        return {
            htPermission: () => this.belongsTo(HtPermission/* , 'ht_permission_id', 'id' */),
        };
    }

    modelAccessors() {
        return {
            ht_permission_group_name: () => this.ht_permission.ht_permission_group.name,
            ht_permission_group_id: () => this.ht_permission.ht_permission_group.name,
        };
    }

    modelInConvertor() {
        return {
            can_read: (n) => Boolean(n),
            can_create: (n) => Boolean(n),
            can_update: (n) => Boolean(n),
            can_delete: (n) => Boolean(n),
        };
    }
}
