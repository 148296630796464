<template>
    <div
        v-if="item && $store.state.programs.programsLoaded"
        class="modal"
    >
        <transition name="fade">
            <div class="planning-event-template-edit-wrapper">
                <form class="form-2 spaced planning-event-template-edit">
                    <div class="modal-content-item-wrapper">
                        <TranslatedForm
                            v-slot="{editingLang}"
                            @input="handleChangeEditingLang"
                        >
                            <ParametersBox :title="translate('General')">
                                <HtFormInput
                                    :id="'name'"
                                    ref="nameInput"
                                    v-model="item.resource.translations.name[editingLang]"
                                    v-validate.disable="{
                                        translation_default:[item.resource.translations.name, shared.session.company.company_languages.default.key],
                                        max:255,
                                    }"
                                    :label="translate('Event name')"
                                    :data-vv-as="translate('name')"
                                    :name="'name'"
                                    :type="'text'"
                                    cypress="even-name"
                                />
                                <HtFormEditor
                                    id="description"
                                    v-model="item.resource.translations.description[editingLang]"
                                    v-validate="descriptionValidation"
                                    :label="translate('Description')"
                                    name="description"
                                    :variables="textVariables"
                                    cypress="event-description"
                                    resource-openai="event"
                                />
                                <HtFormSwitch
                                    v-if="shared.session.have_gsuite"
                                    id="automatic-link"
                                    v-model="item.automatic_link"
                                    :label="translate('Automatic link generation')"
                                    :on-label="translate('Yes')"
                                    :off-label="translate('No')"
                                    name="automatic-link"
                                    :show-optional="false"
                                    :description="translate('By enabling this option, a link will be generated for each event that will be created')"
                                />
                                <HtFormInput
                                    v-if="!shared.session.have_gsuite || !item.automatic_link"
                                    id="link"
                                    ref="linkInput"
                                    v-model="item.link"
                                    :label="translate('Event link')"
                                    :data-vv-as="translate('link')"
                                    name="link"
                                    type="text"
                                    cypress="event-link"
                                />
                                <HtFormSwitch
                                    :id="'toggle-is-enabled'"
                                    v-model="isEnabled"
                                    :label="translate('Save as a draft')"
                                    :on-label="translate('Yes')"
                                    :off-label="translate('No')"
                                    :name="'toggle-is-enabled'"
                                    :show-optional="false"
                                    :disabled="!isResourceTargeted"
                                    :description="translate('By enabling this option, this event will not be integrated in new programs')"
                                    class="mb-0"
                                />
                            </ParametersBox>

                            <ParametersBox
                                v-if="editingLang === shared.session.company.company_languages.default.key"
                                :title="translate('Targeting')"
                            >
                                <HtProgramList
                                    ref="htProgramListInput"
                                    v-model="item.resource.programs[0].company_program_id"
                                    :required="false"
                                    :with-default="false"
                                />
                                <ProgramResourceFilters
                                    v-if="isResourceTargeted"
                                    v-model="item.resource.programs[0].filters"
                                    :program-id="item.resource.programs[0].company_program_id"
                                />
                            </ParametersBox>

                            <ParametersBox :title="translate('Content')">
                                <fieldset :class="{ error: errors.has('uuid') }">
                                    <HtFormEditor
                                        :id="'content'"
                                        v-model="item.translations.content[editingLang]"
                                        :label="translate('Invitation e-mail content')"
                                        :name="'content'"
                                        :variables="textVariables"
                                        cypress="event-email-content"
                                        resource-openai="event"
                                    />
                                    <HtFormSwitch
                                        id="file-join"
                                        v-model="is_file_box_checked"
                                        :label="translate('Attach a file')"
                                        :off-label="translate('No')"
                                        :on-label="translate('Yes')"
                                        name="file-join"
                                        @input="(value) => handleChangeAcceptFile(value, editingLang)"
                                    />
                                    <transition name="fade">
                                        <div
                                            v-if="is_file_box_checked || item.file_translations[editingLang]"
                                            :class="['mb-5', {'margin-t25': (item.file_translations[editingLang] !== null)}]"
                                        >
                                            <TranslatableFiles
                                                :files="item.file_translations"
                                                :errors-messages="errors"
                                                :editing-lang="editingLang"
                                                :validation-rules="{
                                                    translation_default:[
                                                        item.file_translations,
                                                        shared.session.company.company_languages.default.key,
                                                    ]
                                                }"
                                                @input="v => item.file_translations = v"
                                            />
                                        </div>
                                    </transition>
                                </fieldset>
                                <fieldset
                                    v-if="editingLang === shared.session.company.company_languages.default.key"
                                    :class="[{ error: errors.has('organiser') }]"
                                >
                                    <label><t>Organizer</t> *</label>
                                    <ParticipantList
                                        ref="organiserInput"
                                        :single-selection="true"
                                        :type="'stacked'"
                                        :users="organiser ? [organiser] : []"
                                        :add-label="translate('Add an organizer')"
                                        cypress="event-add-organizer"
                                        :class="'mb-5'"
                                        @onAdd="openSearchRole"
                                        @onRemove="resetOrganiser"
                                    />
                                    <input
                                        v-model="organiser"
                                        v-validate.disable="'required'"
                                        type="hidden"
                                        name="organiser"
                                        :data-vv-as="translate('organizer')"
                                    >
                                    <div
                                        v-for="(errorMessage, index) in errors.collect('organiser')"
                                        :key="index"
                                        class="error-message"
                                    >
                                        {{ errorMessage }}
                                    </div>
                                </fieldset>
                                <HtFormSwitch
                                    :id="'delegated'"
                                    v-model="item.is_delegated"
                                    :off-label="translate('No')"
                                    :on-label="translate('Yes')"
                                    :show-optional="false"
                                    :name="'delegated'"
                                    :label="translate('Delegate scheduling to the organizer')"
                                    :description="translate('By enabling this option, the organizer will be able to change the event\'s programming (date, time, duration)')"
                                />
                                <HtFormInput
                                    v-if="editingLang === shared.session.company.company_languages.default.key"
                                    id="place"
                                    v-model="item.place"
                                    :label="translate('Event location')"
                                    :placeholder="translate('Where will the event take place ?')"
                                    name="place"
                                    type="text"
                                />
                                <fieldset
                                    v-if="editingLang === shared.session.company.company_languages.default.key && isResourceTargeted"
                                    :class="{ error: errors.has('participant') }"
                                >
                                    <label><t>Event participants</t> *</label>
                                    <ParticipantList
                                        ref="participantInput"
                                        :can-add="true"
                                        :type="'stacked'"
                                        has-role
                                        :users="item.resource.programs[0].participants"
                                        cypress="event-add-participant"
                                        @onAdd="openAddUsersModal"
                                    />
                                    <input
                                        ref="participant"
                                        v-validate.disable="'required'"
                                        :value="item.resource.programs[0].participants.length ? '-' : ''"
                                        type="hidden"
                                        name="participant"
                                        :data-vv-as="translate('Participants')"
                                    >
                                </fieldset>
                            </ParametersBox>

                            <ParametersBox
                                v-if="editingLang === shared.session.company.company_languages.default.key"
                                :title="translate('Programming')"
                            >
                                <HtKeyDatesInputOrder
                                    v-if="isResourceTargeted"
                                    :program-id="item.resource.programs[0].company_program_id"
                                    :resource-id="item.id"
                                    :filters="item.resource.programs[0].filters"
                                    :is-business-day.sync="item.resource.programs[0].is_business_day"
                                    :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                                    :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                                    :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                                    :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                                    :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                                />
                                <PlanningTimeSlots
                                    v-if="!item.is_delegated"
                                    key="timeslot"
                                    class="full-width"
                                    :from.sync="item.time_start"
                                    :to.sync="item.time_end"
                                    :time_to="23"
                                    :required="true"
                                    cypress="event-time"
                                />
                                <EventTimezoneManager :item="item" />
                                <fieldset>
                                    <label><t>Invitation sending date</t></label>
                                    <div class="event-offset-input">
                                        <HtFormInput
                                            :id="'email_sending_offset'"
                                            v-model.number="item.email_sending_offset"
                                            v-validate.disable="'required'"
                                            min="0"
                                            type="number"
                                            :name="'invitation sending date'"
                                            :data-vv-as="translate('Invitation sending date')"
                                            cypress="event-invite-send-date"
                                        />
                                        <span>
                                            <t :count="item.email_sending_offset">working day before the event | working days before the event</t>
                                        </span>
                                    </div>
                                    <GuessOnWhichEmailItWillBeSend
                                        v-if="professionalEmailSwitchDayOffset !== null"
                                        :professional-email-switch-day-offset="professionalEmailSwitchDayOffset"
                                        :item="item"
                                    />
                                </fieldset>
                                <fieldset>
                                    <RecurrenceConfig
                                        :recurrence="recurrence"
                                        @update="setRecurrence"
                                    />
                                </fieldset>
                            </ParametersBox>
                            <div class="button-container">
                                <Button
                                    v-show="canDelete"
                                    :state="buttonState"
                                    class="negative"
                                    cypress="event-delete"
                                    @click="onDelete()"
                                >
                                    <t>Delete</t>
                                </Button>
                                <Button
                                    v-if="showSaveBtn"
                                    :state="buttonState"
                                    cypress="event-add"
                                    @click="onValidate()"
                                >
                                    {{ labelButton }}
                                </Button>
                            </div>
                        </TranslatedForm>
                    </div>
                </form>
                <modalable
                    ref="modalableSearchRolePanelOrganiser"
                    class="modalable-1 small"
                    :options="{ title: translate('Add Event Organizer') }"
                >
                    <SearchRolePanel
                        ref="searchRolePanelOrganiser"
                        is_assignable_onboardee
                    />
                </modalable>
                <modalable
                    ref="modalableSearchUser"
                    class="modalable-1 small no-padding-bottom"
                    :options="{ title: translate('Add Event Participants') }"
                >
                    <SearchRolePanel
                        ref="searchRolePanel"
                        :multiple-mode="true"
                        :is_assignable_onboardee="true"
                        @onValidate="$refs.modalableSearchUser.close()"
                    />
                </modalable>
                <modalable
                    ref="deleteModal"
                    class="modalable-1 small"
                    :mode="2"
                    :element-delete-label="translate('this survey')"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import EventTimezoneManager from '@/components/globals/EventTimezoneManager.vue';
import PlanningTimeSlots from '@/components/resources/planningEvent/PlanningTimeSlots.vue';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import showButtonActionMixin from '@/components/mixins/showButtonActionMixin';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import 'moment-timezone';
import ResourceKeyDateLabelMixin from '@/mixins/ResourceKeyDateLabelMixin';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import TranslatableFiles from '@/components/form/TranslatableFiles.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import api from '@/store/api/index';
import RecurrenceConfig from '@/components/resources/recurrence/RecurrenceConfig.vue';
import cloneDeep from 'lodash.clonedeep';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'PlanningEventTemplateEdit',
    components: {
        PlanningTimeSlots,
        ParametersBox,
        EventTimezoneManager,
        HtFormSwitch,
        HtProgramList,
        TranslatedForm,
        ProgramResourceFilters,
        TranslatableFiles,
        HtKeyDatesInputOrder,
        RecurrenceConfig,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        showButtonActionMixin,
        ResourceKeyDateLabelMixin,
    ],

    props: {
        id: {
            type: Number,
            default: null,
        },

        companyProgramId: {
            type: Number,
            default: null,
        },

        isDuplicableModel: {
            type: Boolean,
            default: false,
        },

        event: {
            type: Object,
            default: null,
        },

        showSaveBtn: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            item: null,
            buttonState: 'idle',
            is_file_box_checked: false,
            professionalEmailSwitchDayOffset: null, // TODO voir ticket https://heyt.atlassian.net/browse/MAR-276
            textVariables: [
                { name: 'enrollee_firstname', label: 'Firstname' },
                { name: 'enrollee_lastname', label: 'Lastname' },
                { name: 'enrollee_starting_date', label: 'Starting date' },
                { name: 'enrollee_job_title', label: 'Job title' },
                { name: 'enrollee_department', label: 'Department' },
                { name: 'enrollee_contract', label: 'Contract' },
            ],

            translatedFilterSenttrueence: null,
        };
    },

    computed: {
        isResourceTargeted() {
            return this.item && this.item.resource.programs.length > 0 && !!this.item.resource.programs[0].company_program_id;
        },

        labelButton() {
            return this.translate(this.item.id ? 'Save' : 'Add');
        },

        organiser() {
            if (this.item.organiser_user) {
                return { company_user: this.item.organiser_user };
            }

            return this.item.organiser_role
                ? { company_role: this.item.organiser_role }
                : null;
        },

        canDelete() {
            return this.showButtonDelete === true && this.item.id;
        },

        // 8191 is the limit of gsuite event description
        descriptionValidation() {
            return 'max:8191';
        },

        isEnabled: {
            get() {
                return this.isResourceTargeted && !this.item.resource.programs[0].is_enabled;
            },
            set(val) {
                this.item.resource.programs[0].is_enabled = !val;
            },
        },

        recurrence() {
            return {
                recurrence_frequence_value: this.item?.resource?.programs[0]?.recurrence_frequence_value || null,
                recurrence_frequence_unit: this.item?.resource?.programs[0]?.recurrence_frequence_unit || null,
            };
        },
    },

    watch: {
        'item.is_delegated': function (val) {
            // CLEAR TIMES
            if (val) {
                this.item.time_start = null;
                this.item.time_end = null;
            }
        },
        event(val) {
            if (val) {
                this.item = val;
            }
        },
    },

    async created() {
        if (this.id) {
            const event = (await api.configuration.events.get(this.id)).data.data;

            const isResourceTargated = event.resource.programs.length > 0;
            if (!isResourceTargated) {
                // default values for the targeting
                event.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            this.item = event;

            this.is_file_box_checked = !!this.item.file_translations[this.shared.session.company.company_languages.default.key];

            if (this.isDuplicableModel) {
                this.duplicateModel();
            }
        } else if (this.event) {
            this.item = this.event;
        } else {
            this.item = {
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            recurrence_frequence_value: null,
                            recurrence_frequence_unit: null,
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                },
                organiser: {},
                organiser_user: null,
                organiser_role: null,
                timezone: 'Europe/Paris',
                translations: {
                    content: {},
                },
                file_translations: {},
            };
        }
    },

    methods: {
        handleChangeEditingLang(value) {
            this.is_file_box_checked = !!this.item.file_translations[value.key];
        },

        handleChangeAcceptFile(value, editingLang) {
            if (!value) {
                this.item.file_translations[editingLang] = null;
            }
        },

        resetOrganiser() {
            this.item.organiser_role = null;
            this.item.organiser_user = null;
            this.item.organiser_role_id = null;
            this.item.organiser_user_id = null;
        },

        async duplicateModel() {
            const originalResourceProgram = { ...this.item.resource.programs[0] };
            const originalFileTranslations = { ...this.item.file_translations };

            this.item.id = null;
            this.item.is_locked = false;
            this.item.resource.id = null;
            this.item.resource.programs = [
                {
                    company_program_id: this.companyProgramId,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: 'immediately',
                    offset_end_number: 0,
                    offset_end_unit: 'days',
                    is_business_day: true,
                    is_enabled: true,
                    participants: [],
                    filters: [],
                },
            ];

            this.item.company_files = [];
            this.item.file_translations = {};

            for (const [key, value] of Object.entries(originalFileTranslations)) {
                if (value) {
                    const { data } = await api.configuration.events.duplicateFile(this.id, value.id);
                    this.item.file_translations = { ...{ [key]: data.resource }, ...this.item.file_translations };
                }
            }

            this.item.resource.programs[0].company_program_key_date_id = null;
            this.item.resource.programs[0].offset_availability_number = originalResourceProgram.offset_availability_number;
            this.item.resource.programs[0].offset_availability_unit = originalResourceProgram.offset_availability_unit;
            this.item.resource.programs[0].offset_end_number = originalResourceProgram.offset_end_number;
            this.item.resource.programs[0].offset_end_unit = originalResourceProgram.offset_end_unit;
            this.item.resource.programs[0].is_business_day = originalResourceProgram.is_business_day;
            this.item.resource.programs[0].is_enabled = originalResourceProgram.is_enabled;
        },
        openSearchRole() {
            this.$refs.searchRolePanelOrganiser.init({
                selected: this.item.organiser ? [this.item.organiser] : [],

                onSelect: (item) => {
                    this.$refs.modalableSearchRolePanelOrganiser.close();
                    if (item.company_role) {
                        this.item.organiser_role_id = item.id;
                        this.item.organiser_role = item;
                    } else if (item.company_user) {
                        this.item.organiser_user_id = item.id;
                        this.item.organiser_user = item;
                    }

                    this.$forceUpdate();
                },

                onUnselect: () => {
                    this.$refs.modalableSearchRolePanelOrganiser.close();
                    this.resetOrganiser();
                    this.$forceUpdate();
                },
            });

            this.$refs.modalableSearchRolePanelOrganiser.open();
        },

        openAddUsersModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addParticipants,
                selected: this.item.resource.programs[0].participants,
            });
            this.$refs.modalableSearchUser.open();
        },
        addParticipants(participants) {
            this.item.resource.programs[0].participants = participants.map((p) => {
                if (p.company_user) {
                    return {
                        company_user: p.company_user,
                        company_user_id: p.company_user_id,
                    };
                }
                return p;
            });
        },
        async onValidate() {
            await this.$validator.reset();

            const isValid = await this.$validator.validateAll();

            if (!isValid) {
                this.$Utils.scrollToFirstError(this);
                return isValid;
            }

            this.buttonState = 'loading';

            const resourceToSave = cloneDeep(this.item);
            if (!resourceToSave.resource.programs[0].company_program_id) {
                resourceToSave.resource.programs = [];
            }

            try {
                if (this.item.id) {
                    await api.configuration.events.update(this.item.id, resourceToSave);
                } else {
                    await api.configuration.events.create(resourceToSave);
                }
                this.$emit('onSave');
            } finally {
                this.buttonState = 'idle';
            }

            return true;
        },

        async onDelete() {
            if (await this.$refs.deleteModal.deleteWindow({ content: this.translate('Are you sure you want to delete this event? Once you click on delete, you will no longer be able to access this event.') })) {
                this.buttonState = 'loading';
                try {
                    await api.configuration.events.delete(this.item.id);
                    this.$emit('onDelete');
                } finally {
                    this.buttonState = 'idle';
                }
            }
        },

        setRecurrence(value) {
            this.item.resource.programs[0].recurrence_frequence_value = value?.recurrence_frequence_value || null;
            this.item.resource.programs[0].recurrence_frequence_unit = value?.recurrence_frequence_unit || null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.form-2 {
    max-width: 672px;
}

.margin-t25 {
    margin-top: 25px;
}
.button-container {
    display: flex;
    justify-content: center;

    margin-top: 5.6rem;

    .button {
        padding: 1.5rem 4rem;
    }

    .button + .button {
        margin-left: 3rem;
    }
}

.event-offset-input {
    display: flex;
    align-items: center;

    .ht-form-input {
        width: 12.5rem;
        margin-right: 2rem;
    }
}

.modal {
    background-color: white;
    padding-bottom: 4rem;
}

.modal-content {
    margin: auto;
}

.planning-event-template-edit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
<style lang="scss">
.planning-event-template-edit {
    /* we reset some default styling messing everything */
    fieldset, .checkable-container {
        margin: 0 !important;
    }

    .parameters-box {
        display: flex;
        flex-direction: column;
    }

    .wrapper-half-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8rem;

        & > fieldset {
            width: auto;
        }
    }

    .input-order {
        gap: 0.8rem;

        .select {
            width: auto;
            flex-grow: 1;
        }
    }

    .event-offset-input {
        gap: 0.8rem;

        .ht-form-input {
            width: 12.5rem;
            margin-right: 0;
        }
    }

    .flex-container-full {
        margin-bottom: 0;
    }

    .ht-form + .ht-form.ht-switch {
        margin-top: 0.8rem !important;
    }
}
</style>
