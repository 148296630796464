<template>
    <div>
        <ResultSatisfactionScaleNpsItem
            v-for="choice in choices"
            :key="choice.id"
            class="item"
            v-bind="propsToPass(choice)"
        />
    </div>
</template>

<script>
import choiceResultsProps from '../choiceResultsProps';
import ResultSatisfactionScaleNpsItem from './ResultSatisfactionScaleNpsItem.vue';

export default {
    name: 'ResultsSatisfactionScaleNps',
    components: { ResultSatisfactionScaleNpsItem },

    mixins: [
        choiceResultsProps,
    ],
};
</script>
<style lang="scss" scoped>
.item:not(:last-child) {
    margin-bottom: 2%;
}
</style>
