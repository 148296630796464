export default {
    props: {
        choices: {
            type: Array,
            required: true,
        },
        /**
         * answers given by the selected user
         */
        answers: {
            type: Array,
            required: false, // required only if user is selected
        },
        noUserSurveySelected: {
            type: Boolean,
            required: false,
        },
    },

    methods: {
        propsToPass(choice) {
            const data = { answerLabel: choice.label, noUserSurveySelected: this.noUserSurveySelected };
            return this.noUserSurveySelected
                ? { ...data, percent: choice.percent }
                : { ...data, isSelected: choice.id === this.answers[0]?.choice_id };
        },
    },
};
