<template>
    <HtModal
        ref="modal"
        @onClose="$emit('on-close')"
    >
        <template #header>
            {{ title }}
        </template>
        <template #default>
            <ParametersBox>
                <HtFormInput
                    :id="'name'"
                    v-model="customField.name"
                    v-validate.disable="'required'"
                    :name="'name'"
                    :label="translate('Name')"
                    :data-vv-as="translate('name')"
                    :show-optional="false"
                    :disabled="nameDisabled"
                    @input="setCustomFieldKey"
                />
                <HtFormSelect
                    :id="'type'"
                    v-model="customField.type"
                    v-validate.disable="'required'"
                    :name="'type'"
                    :label="translate('Type')"
                    :data-vv-as="translate('type')"
                    :show-optional="false"
                    :options="typeOptions"
                    :disabled="typeDisabled"
                />
                <HtFormInput
                    :id="'key'"
                    :value="customField.key"
                    :name="'key'"
                    :disabled="true"
                    :show-optional="false"
                    :label="translate('Technical key')"
                />
            </ParametersBox>
        </template>
        <template #footer-actions>
            <Button
                v-if="canRemove"
                :state="buttonState"
                class="negative"
                @click="remove()"
            >
                <t>Delete</t>
            </Button>
            <Button
                v-if="canSave"
                :state="buttonState"
                @click="save()"
            >
                <t>Save</t>
            </Button>
        </template>
    </HtModal>
</template>

<script>
import { slugify } from '@/helpers/string';
import HtModal from '../../../globals/HtModal.vue';
import HtFormInput from '../../../globals/HtFormInput.vue';
import ParametersBox from '../../../globals/ParametersBox.vue';
import CompanyFieldTemplate from '../../../../models/CompanyFieldTemplate';

export default {
    name: 'CustomFieldEditModal',
    components: {
        HtModal,
        HtFormInput,
        ParametersBox,
    },

    data() {
        return {
            customField: null,
        };
    },

    computed: {
        title() {
            return this.customField.id ? this.customField.$.name : this.translate('Add a custom field');
        },
        typeOptions() {
            return [
                {
                    id: 'text',
                    name: this.translate('Text'),
                },
                {
                    id: 'boolean',
                    name: this.translate('Yes / No'),
                },
            ];
        },
        canRemove() {
            return Number.isInteger(this.customField.id) && this.isRemovable;
        },
        canSave() {
            return this.isEditable;
        },
        nameDisabled() {
            return this.isNotEditable;
        },
        typeDisabled() {
            return this.isNotEditable || Number.isInteger(this.customField.id);
        },
        isEditable() {
            return this.customField.is_editable;
        },
        isNotEditable() {
            return this.isEditable === false;
        },
        isRemovable() {
            return this.customField.is_removable;
        },
        buttonState() {
            return this.customField._state.isSaving ? 'loading' : 'idle';
        },
    },

    methods: {
        async open(id = null) {
            this.customField = await this.getCustomField(id);

            this.$refs.modal.open();
        },
        async getCustomField(id = null) {
            const customField = new CompanyFieldTemplate([
                'id',
                'key',
                'name',
                'type',
                'table_name',
                'is_editable',
                'is_removable',
            ]);

            if (id) {
                customField.where([
                    ['id', '=', id],
                ]);

                return customField.get();
            }

            customField.is_editable = true;

            customField.table_name = 'company_user';

            customField.options = {
                field_is_editable: customField.field_is_editable || true,
                is_showable: customField.is_showable || true,
                is_removable: customField.is_removable || true,
            };

            return customField;
        },
        async save() {
            const ok = await this.$validator.validateAll();

            if (ok) {
                await this.customField.save();
                this.$refs.modal.close();
            }
        },
        remove() {
            this.customField.delete().then(() => {
                this.$refs.modal.close();
            });
        },
        setCustomFieldKey() {
            if (!this.customField.id) this.customField.key = slugify(this.customField.name);
        },
    },
};
</script>
