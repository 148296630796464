<template>
    <button
        class="ht-button"
        :class="[type, size, loading, brandingClasses, {'icon-only': iconOnly}]"
        :disabled="disabled"
        :data-cy="cypress"
    >
        <template v-if="loading">
            <FontAwesomeIcon
                icon="circle-notch"
                size="lg"
                spin
                class="loading-icon"
            />
            <t
                v-if="loadingLabel"
                class="ml-2"
            >
                {{ loadingLabel }} &hellip;
            </t>
        </template>
        <template v-else>
            <FontAwesomeIcon
                v-if="leftIcon"
                :icon="leftIcon"
                class="button-icon-left"
            />
            <slot />
            <FontAwesomeIcon
                v-if="icon"
                :icon="icon"
                class="button-icon"
            />
        </template>
    </button>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';

export default {
    name: 'HtButton',
    mixins: [
        CypressMixin,
    ],
    props: {
        type: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: 'big',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingLabel: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: [String, Array],
            default: '',
        },
        leftIcon: {
            type: [String, Array],
            default: '',
        },
        iconOnly: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        buttonText() {
            return this.$slots.default[0].text;
        },
        brandingClasses() {
            switch (this.type) {
            case 'primary':
                return 'branding-bg branding-bg-darken-hover branding-bg-lighten-active';
            case 'secondary':
                return 'branding-bg-lighten-active branding-bg-lighten-hover branding-border-color-hover';
            case 'tertiary':
                return 'branding-color branding-color-darken-hover branding-color-lighten-active';
            default:
                return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.ht-button {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.icon-only {
        .button-icon {
            margin-left: 0;
        }

        &.big {
            padding: 8px;
        }

        &.primary {
            &.medium {
                padding: 6px;
            }
        }

        &.secondary {
            &.medium {
                padding: 6px;
            }
        }
    }

    .loading-icon {
        min-height: 22px;
    }
    &.loading {
        cursor: wait;
        pointer-events: none;
    }

    .button-icon {
        margin-left: 8px;
        font-size: 10px;
    }

    .button-icon-left {
        margin-right: 8px;
        font-size: 10px;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:focus-visible {
        outline: 1px solid $semantic-info;
        outline-offset: 2px;
    }
    &.primary {
        font-weight: 700;
        font-family: $lato-bold;

        &:disabled {
            background: $neutral-500 !important;
            color: $neutral-300 !important;
        }
        &.medium {
            padding: 6px 8px;
        }
    }
    &.secondary {
        background: $neutral-white;
        color: $neutral-black;

        &:not(:hover):not(:active){
            border: 1px solid $neutral-300 !important;
        }

        &:disabled {
            color: $neutral-500 !important;
        }
        &.medium {
            padding: 6px 12px;
        }
    }
    &.tertiary {
        background: transparent;
        &:disabled {
            color: $neutral-300 !important;
        }

        &.big, &.medium, &.small {
            padding: 0;
        }
    }
    &.big {
        padding: 8px 16px;
        font-size: 16px;
        line-height: 140%;
    }
    &.medium {
        font-size: 14px;
        line-height: 140%;
    }
    &.small {
        padding: 6px;
        font-size: 12px;
        line-height: 90%;
    }
}

</style>
