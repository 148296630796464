<template>
    <div>
        <div
            v-if="!noUserSurveySelected && occurrences.length > 1"
            class="occurrences-selection"
        >
            <HtChip
                v-for="(occurrence, index) in occurrences"
                :key="occurrence.id"
                :class="{selected: occurrence.id === currentUserSurveyId}"
                :label="`Session ${index + 1}`"
                clickable
                @click.native="$emit('onSelectUserSurvey', occurrence.id, occurrences)"
            />
        </div>

        <SurveyListResultItem
            v-for="questionResults in questionsResults"
            :key="questionResults.question.id"
            :question-results="questionResults"
            :no-user-survey-selected="noUserSurveySelected"
        />
    </div>
</template>

<script>
import HtChip from '@/components/globals/HtChip.vue';
import SurveyListResultItem from './SurveyListResultItem.vue';

export default {
    name: 'SurveyListResult',
    components: {
        HtChip,
        SurveyListResultItem,
    },

    props: {
        questionsResults: {
            type: Array,
            required: true,
        },
        currentUserSurveyId: {
            type: Number,
            required: false,
        },
        occurrences: {
            type: Array,
            required: true,
        },
        noUserSurveySelected: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.occurrences-selection {
    display: flex;
    flex-wrap: wrap;
}
</style>
